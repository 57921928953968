import React from "react";

import {
  Box,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Button,
  Avatar,
} from "@mui/material";
import { Container } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import LoginButton from "./LoginButton";
import SignUpButton from "./SignUpButton";
import DarkModeSwitch from "./DarkModeSwitch";
import GearComparisonSelectorCollapse from "./GearComparisonSelectorCollapse";
import { getColorWithMode } from "../constants/colors";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useMediaQuery } from "react-responsive";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { getLocalUsername, getUserIsLoggedIn } from "../utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import { Backpack } from "lucide-react";

const TopNav = ({
  handleSetAuthenticationIsOpen,
  isLoggedIn,
  handleLogOutUser,
  isSideNavOpen,
  setIsSideNavOpen,
  darkMode,
  showHamburger,
  handleChangeDarkMode,
}) => {
  const [browseGearIsClosed, setBrowseGearIsClosed] = React.useState(true);
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loginAnchorEl, setLoginAnchorEl] = React.useState(null);
  const [loginMenuIsOpen, setLoginMenuIsOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const username = getLocalUsername();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigateToProfile = () => {
    handleClose();
    window.location.assign(`/user/${username}`);
  };

  const handleNavigateToAccount = () => {
    handleClose();
    window.location.assign(`/account`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginMenuClose = (e) => {
    setLoginAnchorEl(null);
    setLoginMenuIsOpen(false);
  };

  return (
    <>
      <Paper
        elevation={0}
        style={{
          position: "sticky",
          top: "0",
          height: "50px",
          borderRadius: "0px",
          width: "100%",
          backgroundColor: getColorWithMode(darkMode, "nav"),
          zIndex: 100,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {showHamburger ? (
            <Stack direction="row" sx={{ float: "left" }}>
              {!isDesktopOrLaptop ? (
                <IconButton
                  sx={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "0px",
                  }}
                  onClick={() => {
                    setIsSideNavOpen(!isSideNavOpen);
                  }}
                >
                  <MenuIcon
                    sx={{
                      height: "40px",
                      width: "40px",
                      transform: `rotateZ(${!isSideNavOpen ? 0 : 90}deg)`,
                      transition: (theme) =>
                        theme.transitions.create("transform", {
                          duration: theme.transitions.duration.shortest,
                        }),
                    }}
                    fontSize="inherit"
                  />
                </IconButton>
              ) : (
                <></>
              )}
              <Link href={"/packs"} sx={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    height: "50px",
                    width: isMobile ? "150px" : "120px",
                    textAlign: "center",
                    verticalAlign: "center",
                    lineHeight: "50px",
                    color: getColorWithMode(darkMode, "lightText"),
                    fontFamily: "helvetica",
                    fontWeight: "bold",
                    "&:hover": { opacity: "0.6", cursor: "pointer" },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                      height: "100%",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Backpack size={"18px"} />
                    <Typography
                      sx={{
                        fontFamily: "helvetica",
                      }}
                    >
                      {getUserIsLoggedIn() ? "My Packs" : "Create"}
                    </Typography>
                  </Stack>
                </Box>
              </Link>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Link href={"/browse"} sx={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    width: isMobile ? "200px" : "170px",
                    height: "50px",
                    textAlign: "center",
                    verticalAlign: "center",
                    lineHeight: "50px",
                    color: getColorWithMode(darkMode, "lightText"),
                    fontFamily: "helvetica",
                    fontWeight: "bold",
                    "&:hover": { opacity: "0.6", cursor: "pointer" },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                      height: "100%",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <TravelExploreIcon fontSize={"small"} />
                    <Typography
                      sx={{
                        fontFamily: "helvetica",
                      }}
                    >
                      Browse Packs
                    </Typography>
                  </Stack>
                </Box>
              </Link>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Box
                sx={{
                  height: "50px",
                  width: isMobile ? "220px" : "200px",
                  textAlign: "center",
                  verticalAlign: "center",
                  lineHeight: "50px",
                  color: getColorWithMode(darkMode, "lightText"),
                  fontFamily: "helvetica",
                  fontWeight: "bold",
                  "&:hover": { opacity: "0.6", cursor: "pointer" },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setBrowseGearIsClosed(!browseGearIsClosed);
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <StorefrontIcon fontSize="small" />
                  <Typography
                    sx={{
                      fontFamily: "helvetica",
                    }}
                  >
                    Explore Gear
                  </Typography>
                  <KeyboardArrowDownIcon />
                </Stack>
              </Box>
            </Stack>
          ) : (
            <Container>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  height: "50px",
                  "&:hover": {
                    opacity: 0.8,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  window.location = `${window.location.origin}?navigatedToHome=true`;
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "40px",
                    objectFit: "cover",
                  }}
                  src="/pack_wizard.png"
                />
                <Typography
                  sx={{
                    marginLeft: "10px",
                    fontFamily: "'Righteous', 'sans-serif'",
                    fontSize: isMobile ? "32px" : "32px",
                    float: "left",
                  }}
                >
                  PackWizard
                </Typography>
              </Stack>
            </Container>
          )}
          <Stack
            gap={2}
            alignItems="center"
            direction="row"
            style={{
              maxWidth: isMobile ? "100px" : "350px",
              height: "50px",
              paddingRight: "15px",
            }}
          >
            <Box>
              {isLoggedIn ? (
                <>
                  {isMobile ? (
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                    >
                      <PersonIcon sx={{ width: 45, height: 45 }} />
                    </IconButton>
                  ) : (
                    <Button
                      variant="outlined"
                      aria-haspopup="true"
                      onClick={handleClick}
                      sx={{ textTransform: "none" }}
                      endIcon={<KeyboardArrowDown />}
                    >
                      {`@${username}`}
                    </Button>
                  )}
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {isMobile && (
                      <MenuItem
                        sx={{
                          width: "220px",
                          height: "70px",
                          fontSize: "24px",
                          color: "grey",
                          textAlign: "center",
                        }}
                      >
                        {`@${username}`}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={handleNavigateToAccount}
                      sx={{
                        ...(isMobile
                          ? {
                              width: "220px",
                              height: "70px",
                              fontSize: "24px",
                            }
                          : {}),
                      }}
                    >
                      My account
                    </MenuItem>
                    <MenuItem
                      onClick={handleNavigateToProfile}
                      sx={{
                        ...(isMobile
                          ? {
                              width: "220px",
                              height: "70px",
                              fontSize: "24px",
                            }
                          : {}),
                      }}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "#b23b3b",
                        ...(isMobile
                          ? {
                              width: "220px",
                              height: "70px",
                              fontSize: "24px",
                            }
                          : {}),
                      }}
                      onClick={handleLogOutUser}
                    >
                      Logout
                    </MenuItem>
                    {isMobile && (
                      <MenuItem
                        sx={{
                          width: "220px",
                          height: "70px",
                          fontSize: "24px",
                        }}
                        onClick={handleChangeDarkMode}
                      >
                        <DarkModeSwitch
                          onChange={handleChangeDarkMode}
                          checked={darkMode}
                        />
                      </MenuItem>
                    )}
                  </Menu>
                </>
              ) : isMobile ? (
                <>
                  <IconButton
                    onClick={(e) => {
                      if (loginMenuIsOpen) {
                        handleLoginMenuClose();
                      } else {
                        setLoginAnchorEl(e.currentTarget);
                        setLoginMenuIsOpen(true);
                      }
                    }}
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    <PersonIcon sx={{ width: 50, height: 50 }} />
                  </IconButton>
                  <Menu
                    anchorEl={loginAnchorEl}
                    open={loginMenuIsOpen}
                    onClose={handleLoginMenuClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleSetAuthenticationIsOpen("LOGIN");
                        handleLoginMenuClose();
                      }}
                      sx={{
                        width: "220px",
                        height: "80px",
                        fontSize: "24px",
                      }}
                      divider
                    >
                      Login
                    </MenuItem>
                    <MenuItem
                      sx={{
                        width: "220px",
                        height: "80px",
                        fontSize: "24px",
                      }}
                      onClick={() => {
                        handleSetAuthenticationIsOpen(true, "SIGNUP");
                        handleLoginMenuClose();
                      }}
                      divider
                    >
                      Sign Up
                    </MenuItem>
                    <MenuItem
                      sx={{
                        width: "220px",
                        height: "80px",
                        fontSize: "24px",
                      }}
                      onClick={handleChangeDarkMode}
                    >
                      <DarkModeSwitch
                        onChange={handleChangeDarkMode}
                        checked={darkMode}
                      />
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Stack
                  gap={2}
                  direction="row"
                  alignItems="center"
                  sx={{ height: "50px" }}
                >
                  <LoginButton
                    handleSetAuthenticationIsOpen={
                      handleSetAuthenticationIsOpen
                    }
                  />
                  <SignUpButton
                    handleSetAuthenticationIsOpen={
                      handleSetAuthenticationIsOpen
                    }
                  />
                </Stack>
              )}
            </Box>
            {isMobile ? (
              <></>
            ) : (
              <DarkModeSwitch
                onChange={handleChangeDarkMode}
                checked={darkMode}
              />
            )}
          </Stack>
        </Stack>
      </Paper>
      {showHamburger ? (
        <GearComparisonSelectorCollapse
          underNavBar
          darkMode={darkMode}
          browseGearIsOpen={browseGearIsClosed}
          setBrowseGearIsClosed={setBrowseGearIsClosed}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TopNav;
