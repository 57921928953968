import React from "react";
import { Container } from "@mui/system";
import { getColorWithMode } from "../constants/colors";
import { Grid, Link, Paper, Stack, Typography } from "@mui/material";

import { getArticleMetadata } from "../utils/articles/generator";
import moment from "moment";

const ArticleCard = (article) => {
  return (
    <Paper sx={{ width: "360px", borderRadius: "10px" }}>
      <Stack textAlign="left">
        <Container
          sx={{
            padding: "0px 0px 0px 0px",
            width: "360px",
            height: "200px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url("${article.headerImage}")`,
            display: "flex",
            borderRadius: "10px 10px 0px 0px",
            alignItems: "end",
          }}
        />
        <Container sx={{ paddingBottom: "20px" }}>
          <Stack gap={2}>
            <Typography
              variant="subtitle2"
              sx={{ fontStyle: "italic", color: "gray" }}
            >{`Article Updated: ${moment(article.date).format(
              "ddd MMM DD, YYYY"
            )}`}</Typography>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "500",
                fontFamily: `'Abril Fatface', Abril, "Times New Roman", Times, serif`,
                fontSize: "2em",
              }}
            >
              {article.title}
            </Typography>
            <Typography variant="body1">
              {article.subtitle.length > 100
                ? `${article.subtitle.slice(0, 100)}...`
                : article.subtitle}
            </Typography>
            <Link
              href={`/article/${article.path}`}
              sx={{ textDecoration: "none" }}
            >
              Read More
            </Link>
          </Stack>
        </Container>
      </Stack>
    </Paper>
  );
};

const ArticleMainContainer = ({ articles, isDarkMode }) => {
  return (
    <Container
      sx={{
        minWidth: "360px",
        width: "100%",
        maxWidth: "1440px",
        backgroundColor: getColorWithMode(isDarkMode, "base"),
        maxHeight: "calc(100vh - 50px)",
        minHeight: "100vh",
        overflowY: "scroll",
        textAlign: "center",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: "3em" }}>
        Articles
      </Typography>
      <Grid container spacing={2}>
        {articles.map((article) => (
          <Grid item>
            <ArticleCard key={article._id} {...getArticleMetadata(article)} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ArticleMainContainer;
