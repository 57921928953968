import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { getColorWithMode } from "../../constants/colors";
import BrandBuyButton from "./BrandBuyButton";
import DealsPriceHistory from "./DealsPriceHistory";
import PackWizardCompareLinkButton from "../PackWizardCompareLinkButton";
import momenttz from "moment-timezone";
import React from "react";
import { getDailyDealsByGearType } from "../../utils";

const ITEM_TYPE_TO_COMPARE_TYPE = {
  sleepingPad: "PACKWIZARD_SP",
  tent: "PACKWIZARD_TENT",
  backpack: "PACKWIZARD_PACK",
  sleepingBag: "PACKWIZARD_SB",
  insulatedJacket: "PACKWIZARD_IJ",
  stove: "PACKWIZARD_ST",
};

const GEAR_TYPE_TO_TEXT = {
  tent: "Shelters",
  sleepingPad: "Sleeping Pads",
  backpack: "Packs",
  insulatedJacket: "Insulated Jackets",
  sleepingBag: "Sleeping Bags & Quilts",
  stove: "Stoves",
};

const ItemCard = ({
  index,
  itemId,
  itemType,
  brand,
  imageUrl,
  name,
  retailers,
  darkMode,
}) => {
  const retailer = retailers[0];
  if (!retailer || retailer.retailPrice === retailer.salePrice) {
    return <></>;
  }

  const percentageOff =
    ((retailer.retailPrice - retailer.salePrice) / retailer.retailPrice) * 100;

  return (
    <Card sx={{ width: "220px", marginTop: "10px" }}>
      <Stack direction="row" justifyContent="flex-end">
        {index === 0 ? (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              backgroundColor: "#d4af37",
              borderRadius: "3px",
              marginTop: "-10px",
              paddingLeft: "3px",
              paddingRight: "3px",
            }}
          >
            Best Deal
          </Paper>
        ) : (
          <Paper
            sx={{
              position: "absolute",
              borderRadius: "3px",
              marginBottom: "-10px",
              paddingLeft: "3px",
              paddingRight: "3px",
              zIndex: 1000,
            }}
          >
            {`#${index + 1}`}
          </Paper>
        )}
      </Stack>
      <CardContent sx={{ paddingBottom: "0px" }}>
        <Stack justifyContent="center">
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "white",
              width: "190px",
              minWidth: "150px",
              height: "150px",
              overflow: "hidden",
              position: "relative",
              borderRadius: "5px",
            }}
          >
            <Box
              sx={{
                borderRadius: "5px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: 150,
                width: 150,
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              alt={`${brand} ${name}`}
              src={imageUrl}
            />
          </Box>
          <Typography
            textAlign="left"
            variant="subtitle2"
            sx={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: getColorWithMode(darkMode, "lightText"),
            }}
          >
            {name}
          </Typography>
          <Typography
            textAlign="left"
            variant="subtitle2"
            sx={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginTop: "-5px",
              color: getColorWithMode(darkMode, "lightShadow"),
            }}
          >
            {brand}
          </Typography>
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack>
              <Typography textAlign="left" variant="body1" color="#4caf50">
                {`$${Number(retailer.salePrice).toFixed(2)}`}
              </Typography>
              <Stack direction="row" gap={1}>
                <Typography
                  textAlign="left"
                  variant="subtitle2"
                  sx={{ textDecoration: "line-through" }}
                >
                  {`$${Number(retailer.retailPrice).toFixed(2)}`}
                </Typography>
                <Typography textAlign="left" variant="subtitle2">
                  ({`${percentageOff.toFixed(1)}% off`})
                </Typography>
              </Stack>
            </Stack>
            <DealsPriceHistory
              itemId={itemId}
              itemType={itemType}
              darkMode={darkMode}
            />
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Stack>
          <BrandBuyButton
            retailer={retailer.retailer}
            url={retailer.buyLink}
            darkMode={darkMode}
            size="small"
            width="200px"
          />
          <PackWizardCompareLinkButton
            id={itemId}
            compareType={ITEM_TYPE_TO_COMPARE_TYPE[itemType]}
            useSmall={true}
            text="View on PackWizard"
          />
        </Stack>
      </CardActions>
    </Card>
  );
};

const GearDealsContainer = ({ gearType, darkMode }) => {
  const [deals, setDeals] = React.useState([]);
  const [dealDate, setDealDate] = React.useState(Date.now());
  const [maxShow, setMaxShow] = React.useState(6);

  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  React.useEffect(() => {
    (async () => {
      try {
        const { dealList, lastUpdated } = await getDailyDealsByGearType(
          gearType
        );
        setDeals(dealList);
        setDealDate(lastUpdated);
      } catch (error) {
        console.log(error);
        setDeals([]);
      }
    })();
  }, []);

  return (
    <Paper elevation={4} sx={{ padding: "10px 10px 10px 10px" }}>
      <Stack gap={1}>
        <Stack sx={{ paddingLeft: "5px" }}>
          <Typography variant="h5">
            {`Best deals on ${GEAR_TYPE_TO_TEXT[gearType]}`}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              color: getColorWithMode(darkMode, "lightShadow"),
            }}
          >
            {`Updated at: ${momenttz(dealDate)
              .tz(timeZoneString)
              .format("ddd MMM DD YYYY @ hh:mm a zz")}`}
          </Typography>
          <Link href="/gear" variant="button">
            See All Deals
          </Link>
        </Stack>
        {deals && deals.length > 0 ? (
          <>
            <Grid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, 220px)", //the width of the card
                justifyContent: "center",
                gridGap: "10px",
              }}
            >
              {deals.slice(0, maxShow).map((deal, index) => (
                <Grid item key={`deal-${index}`}>
                  <ItemCard
                    {...deal}
                    darkMode={darkMode}
                    index={index}
                    itemType={gearType}
                  />
                </Grid>
              ))}
            </Grid>
            {maxShow < deals.length ? (
              <Button
                onClick={() => {
                  setMaxShow(maxShow + 6);
                }}
              >
                Show More Deals
              </Button>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ color: getColorWithMode(darkMode, "lightShadow") }}
          >
            No Deals Found
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default GearDealsContainer;
