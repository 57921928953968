import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { interpolateColors } from "../utils/colorGenerator";
import { interpolateTurbo } from "d3-scale-chromatic";
import DoughnutChart from "./DoughnutChart";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import { Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import LoadingPackVisualization from "./LoadingPackVisualization";
import TooltipToggleButton from "./TooltipToggleButton";
import CategoryIcon from "@mui/icons-material/Category";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useMediaQuery } from "react-responsive";
import PackVisualizationTableCarousel from "./PackVisualizationTableCarousel";

const renderEmptyChartPlaceholder = (isShared, isDesktopOrLaptop, isMobile) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      width: isMobile ? "660px" : "400px",
      height: isMobile ? "300px" : "200px",
      fontSize: isMobile ? "20px" : "16px",
    }}
  >
    <Typography textAlign="center">
      {isShared
        ? "This pack has no items in it"
        : "Add items to your pack to begin visualizing your base weight"}
    </Typography>
  </Stack>
);

const colorScale = interpolateTurbo;
const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: true,
};

const PackVisualization = ({
  labels,
  packDataArray,
  unit,
  weightsInGrams,
  isShared,
  categoryDataArray,
  categoryLabelArray,
  toggleState,
  setToggleState,
  packIsLoading,
  isDesktopOrLaptop,
  setSideNavIsOpen,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [dataArrayToUse, setDataArrayToUse] = React.useState(packDataArray);
  const [labelsToUse, setLabelsToUse] = React.useState(labels);
  const [colorArray, setColorArray] = React.useState(
    interpolateColors(dataArrayToUse.length, colorScale, colorRangeInfo)
  );
  const [showDonut, setShowDonut] = React.useState(
    localStorage.getItem("useDonutVisualization")
      ? localStorage.getItem("useDonutVisualization") === "true"
      : true
  );
  const [sliceLabelInfo, setSliceLabelInfo] = React.useState({
    label: "",
    weight: "",
  });

  React.useEffect(() => {
    setDataArrayToUse(toggleState ? packDataArray : categoryDataArray);
    setLabelsToUse(toggleState ? labels : categoryLabelArray);
    setColorArray(
      toggleState
        ? interpolateColors(packDataArray.length, colorScale, colorRangeInfo)
        : interpolateColors(
            categoryDataArray.length,
            colorScale,
            colorRangeInfo
          )
    );
    setSliceLabelInfo({
      label: "",
      weight: "",
    });
  }, [
    toggleState,
    packDataArray,
    categoryDataArray,
    labels,
    categoryLabelArray,
  ]);

  const handleChooseVisualization = () => {
    setShowDonut(!showDonut);
    setSliceLabelInfo({
      label: "",
      weight: "",
    });
    localStorage.setItem("useDonutVisualization", String(!showDonut));
  };

  const handleToggleChange = (e, values) => {
    if (values[0] === "VISUAL_TYPE") {
      setToggleState(!toggleState);
    }
    if (values[0] === "SHOW_DONUT") {
      handleChooseVisualization();
    }
  };

  return (
    <Paper
      elevation={2}
      sx={{
        width: isMobile ? "700px" : "450px",
        height: isMobile ? "355px" : "245px",
      }}
    >
      {packIsLoading ? (
        <LoadingPackVisualization />
      ) : (
        <Stack>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                setSideNavIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack spacing={0} direction="row">
            <Stack
              sx={{
                width: isMobile ? "300px" : "200px",
              }}
            >
              <Container>
                {dataArrayToUse.length ? (
                  <DoughnutChart
                    labelState={labelsToUse}
                    packDataArray={dataArrayToUse}
                    colorArray={colorArray}
                    sliceLabelInfo={sliceLabelInfo}
                    setSliceLabelInfo={setSliceLabelInfo}
                  />
                ) : (
                  renderEmptyChartPlaceholder(
                    isShared,
                    isDesktopOrLaptop,
                    isMobile
                  )
                )}
              </Container>
              {dataArrayToUse.length ? (
                <Box
                  sx={{
                    marginLeft: "5px",
                    marginTop: "-5px",
                  }}
                >
                  <ToggleButtonGroup onChange={handleToggleChange}>
                    <TooltipToggleButton
                      title={`Visualize By ${
                        toggleState ? "Category" : "Item"
                      }`}
                      selected={!toggleState}
                      value="VISUAL_TYPE"
                      onChange={() => {
                        setToggleState(!toggleState);
                      }}
                      sx={{
                        width: isMobile ? "50px" : "35px",
                        height: isMobile ? "50px" : "35px",
                      }}
                    >
                      <CategoryIcon size={isMobile ? "large" : ""} />
                    </TooltipToggleButton>
                  </ToggleButtonGroup>
                </Box>
              ) : (
                <></>
              )}
            </Stack>
            <Divider sx={{ marginTop: "5px" }} />
            <PackVisualizationTableCarousel
              isDesktopOrLaptop={isDesktopOrLaptop}
              weightsInGrams={weightsInGrams}
              unit={unit}
              colorArray={colorArray}
              packDataArray={packDataArray}
              categoryDataArray={categoryDataArray}
            />
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};

export default PackVisualization;
