function convertGearListToCSV(gearListData) {
  try {
    // Get all unique keys from all items across all lists
    const excludedKeys = [
      "affiliateLink",
      "gearClosetId",
      "isSelected",
      "searchName",
      "defaultCategory",
      "summaryRowUnit",
      "weightType",
      "id",
      "userId",
      "__v",
    ];
    const allKeys = new Set();

    // Add list-level keys first
    allKeys.add("listTitle");
    allKeys.add("listSubtitle");
    allKeys.add("markedAsWeightType");

    // Gather all unique item keys
    gearListData.forEach((list) => {
      Object.values(list.rows).forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!excludedKeys.includes(key)) {
            allKeys.add(key);
          }
        });
      });
    });

    // Convert Set to Array and sort for consistent column order
    const headers = Array.from(allKeys).sort();

    // Create array to store all rows
    let rows = [];

    // Add header row
    rows.push(headers.join(","));

    // Process each gear list
    gearListData.forEach((list) => {
      // Process each item in the rows object
      Object.values(list.rows).forEach((item) => {
        // Create array for this row's data
        let row = headers.map((header) => {
          let value;

          // Handle list-level fields
          if (header === "listTitle") value = list.title;
          else if (header === "listSubtitle") value = list.subtitle;
          else if (header === "markedAsWeightType")
            value = list.markedAsWeightType;
          // Handle item-level fields
          else value = item[header];

          // Handle different value types
          if (value === null || value === undefined) {
            return "";
          } else if (typeof value === "string") {
            // Escape quotes and wrap in quotes
            return `"${value.replace(/"/g, '""')}"`;
          } else {
            return value;
          }
        });

        // Add row to rows array
        rows.push(row.join(","));
      });
    });

    // Join all rows with newlines
    return rows.join("\n");
  } catch (error) {
    return "Something went wrong";
  }
}

function downloadCSV(csvContent, filename = "gear_list.csv") {
  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary link element
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // Append link to body (required for Firefox)
  document.body.appendChild(link);

  // Trigger download
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export const exportToCSV = (name, tableData) => {
  const csvContent = convertGearListToCSV(tableData);
  downloadCSV(csvContent, `${name}.csv`);
};
