import React from "react";
import { getDealsForDashboard } from "../../utils";
import {
  Avatar,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DealCarousel from "./DealCarousel";
import { getColorWithMode } from "../../constants/colors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GearExploreList from "../GearExploreList";
import { logUserInteraction } from "../../api/logging";

const gearTypeToPathMap = {
  tent: "tent",
  sleepingPad: "sleeping-pad",
  sleepingBag: "sleeping-bag",
  backpack: "backpacks",
  insulatedJacket: "insulated-jacket",
  stove: "stove",
};

const getProductLinkPath = (gearType, productId) => {
  const typePath = gearTypeToPathMap[gearType];
  return `/gear/${typePath}/${productId}`;
};

const getTextFromgearType = (gearType) => {
  let productType = "products";
  if (gearType === "tent") {
    productType = "tents";
  }
  if (gearType === "sleepingPad") {
    productType = "sleeping pads";
  }
  if (gearType === "sleepingBag") {
    productType = "sleeping bags";
  }
  if (gearType === "backpack") {
    productType = "backpacks";
  }
  if (gearType === "insulatedJacket") {
    productType = "Insulated Jackets";
  }
  if (gearType === "stove") {
    productType = "stoves";
  }

  return productType || "";
};

const GeneralGearContainer = ({ darkMode }) => {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    (async () => {
      logUserInteraction("session", "deals");
      const data = await getDealsForDashboard();
      setData(data);
    })();
  }, []);

  return (
    <Container sx={{ padding: "0px" }}>
      <Container
        sx={{
          width: "100%",
          backgroundColor: getColorWithMode(darkMode, "title"),
        }}
      >
        <Stack
          textAlign="center"
          alignItems="center"
          sx={{ padding: "20px 10px 20px 10px" }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontFamily: "Helvetica",
              color: "white",
            }}
          >
            Gear Overview
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Helvetica",
              color: "white",
              maxWidth: "650px",
            }}
          >
            Sales, Deals and Tools to help find your next piece of gear
          </Typography>
        </Stack>
      </Container>
      <Stack
        alignContent="center"
        spacing={3}
        sx={{ width: "100%", padding: "10px" }}
      >
        {data ? (
          <>
            <DealCarousel
              deals={data.allDeals}
              darkMode={darkMode}
              dealDate={data.date}
            />
            <Paper elevation={4} sx={{ padding: "10px 10px 10px 10px" }}>
              <Stack textAlign="center" alignItems="center">
                <Typography variant="h5" sx={{ padding: "10px" }}>
                  Most Popular Gear On PackWizard
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  gap={1}
                  maxWidth="900px"
                >
                  {Object.keys(data.mostPopular).map((mostPopularKey) => (
                    <Paper
                      key={mostPopularKey}
                      sx={{
                        width: "275px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ paddingLeft: "7px" }}
                      >
                        <Typography
                          sx={{
                            paddingTop: "10px",
                            paddingBottom: "5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {getTextFromgearType(mostPopularKey)}
                        </Typography>
                        <Tooltip
                          title={`These are the most viewed ${getTextFromgearType(
                            mostPopularKey
                          )} on PackWizard`}
                        >
                          <InfoOutlinedIcon
                            sx={{
                              paddingTop: "3px",
                              width: "20px",
                              height: "20px",
                              opacity: 0.6,
                            }}
                          />
                        </Tooltip>
                      </Stack>
                      <Divider />
                      <Stack gap={0.5} sx={{ paddingTop: "5px" }}>
                        {data.mostPopular[mostPopularKey].map((item) => (
                          <Link
                            sx={{ textDecoration: "none" }}
                            href={getProductLinkPath(mostPopularKey, item._id)}
                          >
                            <Card
                              sx={{
                                height: "42px",
                                "&:hover": { opacity: 0.7, cursor: "pointer" },
                              }}
                            >
                              <Stack direction="row" gap={1}>
                                <Avatar
                                  alt={item.name}
                                  variant="square"
                                  src={item.imageUrl}
                                />
                                <Stack
                                  sx={{ maxWidth: "160px", width: "160px" }}
                                >
                                  <Tooltip
                                    title={`${item.name}${
                                      item.variant ? ` (${item.variant})` : ""
                                    }`}
                                  >
                                    <Typography
                                      textAlign="left"
                                      variant="subtitle2"
                                      sx={{
                                        maxWidth: "200px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        color: getColorWithMode(
                                          darkMode,
                                          "lightText"
                                        ),
                                      }}
                                    >
                                      {`${item.name}${
                                        item.variant ? ` (${item.variant})` : ""
                                      }`}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    textAlign="left"
                                    variant="subtitle2"
                                    sx={{
                                      maxWidth: "200px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      marginTop: "-5px",
                                      color: getColorWithMode(
                                        darkMode,
                                        "lightShadow"
                                      ),
                                    }}
                                  >
                                    {item.formattedBrand}
                                  </Typography>
                                </Stack>
                                <Divider
                                  variant="middle"
                                  orientation="vertical"
                                  flexItem
                                />
                                <Stack
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    {`${item.weight.toFixed(0)}g`}
                                  </Typography>
                                  <Divider />
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: getColorWithMode(
                                        darkMode,
                                        "lightShadow"
                                      ),
                                    }}
                                  >
                                    {item.price
                                      ? `$${item.price.toFixed(0)}`
                                      : "-"}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Card>
                          </Link>
                        ))}
                      </Stack>
                    </Paper>
                  ))}
                </Grid>
              </Stack>
            </Paper>
          </>
        ) : (
          <></>
        )}
        <Paper elevation={4} sx={{ padding: "10px 10px 10px 10px" }}>
          <Stack textAlign="center" alignItems="center">
            <Typography variant="h5" sx={{ padding: "10px" }}>
              Explore Gear
            </Typography>
            <GearExploreList justifyContent={"center"} />
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};

export default GeneralGearContainer;
