const lightMode = {
  base: "#FFFFFF",
  secondary: "#DEDEDE",
  title: "#676f99",
  text: "#333",
  shadow: "#AAA",
  lightShadow: "#888",
  nav: "#FFFFFF",
  lightText: "#444",
  success: "#2e7d32",
  danger: "#d32f2f",
  navBottom: "#EEE",
};

const darkMode = {
  nav: "#2E2E2E",
  base: "#2E2E2E",
  secondary: "#3E3E3E",
  title: "#272F5A",
  text: "white",
  shadow: "#222",
  lightShadow: "grey",
  lightText: "#F0F0F0",
  success: "#4caf50",
  danger: "#ef5350",
  navBottom: "#292929",
};

module.exports = {
  STYLISH_DARK: "#2E2E2E",
  getColorWithMode: (isDarkMode, key) => {
    return isDarkMode ? darkMode[key] : lightMode[key];
  },
};
