import * as React from "react";
import Popover from "@mui/material/Popover";
import Fade from "@mui/material/Fade";
import { Box, Button, Paper, Tooltip, Typography } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import { getPriceHistory } from "../../utils";
import PriceHistory from "./PriceHistory";
import { getColorWithMode } from "../../constants/colors";

const DealsPriceHistory = ({ itemId, itemType, darkMode }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [retailers, setRetailers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      if (retailers.length === 0) {
        (async () => {
          const retailersForItem = await getPriceHistory(itemId, itemType);
          if (!retailersForItem || retailersForItem.error) {
            setRetailers([]);
          } else {
            setRetailers(retailersForItem);
          }
          setLoading(false);
        })();
      } else {
        setLoading(false);
      }
    }
  }, [open]);

  return (
    <Tooltip title="Price History" placement="top">
      <div>
        <Button
          size="small"
          variant="text"
          style={{ fontSize: "12px", width: "105px" }}
          onClick={handleClick}
        >
          Price History
        </Button>
        <Popover
          id={itemId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Paper
            elevation={5}
            sx={{
              width: "500px",
              height: "300px",
              zIndex: 100,
              padding: "10px",
            }}
          >
            <Typography textAlign="center">Price History</Typography>
            <Paper
              sx={{
                paddingTop: "10px",
                marginTop: "5px",
                width: "480px",
                height: "250px",
              }}
            >
              {loading ? (
                <Typography textAlign="center" sx={{ lineHeight: "250px" }}>
                  Loading...
                </Typography>
              ) : (
                <>
                  {retailers && retailers.length ? (
                    <PriceHistory retailers={retailers} darkMode={darkMode} />
                  ) : (
                    <Typography textAlign="center" sx={{ lineHeight: "250px" }}>
                      No Price History Found
                    </Typography>
                  )}
                </>
              )}
            </Paper>
          </Paper>
        </Popover>
      </div>
    </Tooltip>
  );
};

export default DealsPriceHistory;
