import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { getColorWithMode } from "../../constants/colors";
import BrandBuyButton from "./BrandBuyButton";
import DealsPriceHistory from "./DealsPriceHistory";
import PackWizardCompareLinkButton from "../PackWizardCompareLinkButton";
import momenttz from "moment-timezone";
import React from "react";
import { externalLinkWrapper } from "../../utils/links";

const ITEM_TYPE_TO_COMPARE_TYPE = {
  sleepingPad: "PACKWIZARD_SP",
  tent: "PACKWIZARD_TENT",
  backpack: "PACKWIZARD_PACK",
  sleepingBag: "PACKWIZARD_SB",
  insulatedJacket: "PACKWIZARD_IJ",
  stove: "PACKWIZARD_ST",
};

const ITEM_TYPE_MAP = {
  sleepingPad: "Sleeping Pads",
  tent: "Tents",
  backpack: "Backpacks",
  sleepingBag: "Sleeping Bags",
  insulatedJacket: "Insulated Jackets",
  stove: "Stoves",
};

const ItemCard = ({
  index,
  itemId,
  itemType,
  brand,
  imageUrl,
  name,
  retailers,
  darkMode,
  filters,
}) => {
  const retailer = retailers.find(({ retailer }) =>
    filters.retailers.includes(retailer)
  );
  if (!retailer || retailer.retailPrice === retailer.salePrice) return <></>;

  const percentageOff =
    ((retailer.retailPrice - retailer.salePrice) / retailer.retailPrice) * 100;

  return (
    <Grid item>
      <Card sx={{ width: "220px", marginTop: "10px" }}>
        <Stack direction="row" justifyContent="flex-end">
          {index === 0 ? (
            <Paper
              elevation={3}
              sx={{
                position: "absolute",
                backgroundColor: "#d4af37",
                borderRadius: "3px",
                marginTop: "-10px",
                paddingLeft: "3px",
                paddingRight: "3px",
              }}
            >
              Best Deal
            </Paper>
          ) : (
            <Paper
              sx={{
                position: "absolute",
                borderRadius: "3px",
                marginBottom: "-10px",
                paddingLeft: "3px",
                paddingRight: "3px",
                marginTop: "-10px",
                color: getColorWithMode(darkMode, "lightText"),
              }}
            >
              {`#${index + 1}`}
            </Paper>
          )}
        </Stack>
        <CardContent sx={{ paddingBottom: "0px" }}>
          <Stack justifyContent="center">
            <Box
              onClick={() => {
                externalLinkWrapper(retailer.buyLink);
              }}
              sx={{
                textAlign: "center",
                backgroundColor: "white",
                width: "190px",
                minWidth: "150px",
                height: "150px",
                overflow: "hidden",
                position: "relative",
                borderRadius: "5px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: 150,
                  width: 150,
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                alt={`${brand} ${name}`}
                src={imageUrl}
              />
            </Box>
            <Typography
              textAlign="left"
              variant="subtitle2"
              sx={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: getColorWithMode(darkMode, "lightText"),
              }}
            >
              {name}
            </Typography>
            <Typography
              textAlign="left"
              variant="subtitle2"
              sx={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "-5px",
                color: getColorWithMode(darkMode, "lightShadow"),
              }}
            >
              {brand}
            </Typography>
            <Stack>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography textAlign="left" variant="body1" color="#4caf50">
                  {`$${Number(retailer.salePrice).toFixed(2)}`}
                </Typography>
                <DealsPriceHistory
                  itemId={itemId}
                  itemType={itemType}
                  darkMode={darkMode}
                />
              </Stack>
              <Stack direction="row" gap={1}>
                <Typography
                  textAlign="left"
                  variant="subtitle2"
                  sx={{ textDecoration: "line-through" }}
                >
                  {`$${Number(retailer.retailPrice).toFixed(2)}`}
                </Typography>
                <Typography textAlign="left" variant="subtitle2">
                  ({`${percentageOff.toFixed(1)}% off`})
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Stack>
            <BrandBuyButton
              retailer={retailer.retailer}
              url={retailer.buyLink}
              darkMode={darkMode}
              size="small"
              width="200px"
            />
            <PackWizardCompareLinkButton
              id={itemId}
              compareType={ITEM_TYPE_TO_COMPARE_TYPE[itemType]}
              useSmall={true}
              text="View on PackWizard"
            />
          </Stack>
        </CardActions>
      </Card>
    </Grid>
  );
};

const SORT_BY_KEYS = {
  PERCENTAGE_OFF: "PERCENTAGE_OFF",
  DOLLAR_OFF: "DOLLAR_OFF",
};

const DealCarousel = ({ dealDate, deals, darkMode }) => {
  const [availableSelections, setAvailableSelections] = React.useState({
    retailers: [],
  });
  const [filters, setFilters] = React.useState({
    sortBy: SORT_BY_KEYS.PERCENTAGE_OFF,
    retailers: [],
    gearTypes: Object.keys(ITEM_TYPE_MAP),
  });
  const [maxShow, setMaxShow] = React.useState(24);

  const filterDeals = (deals) => {
    const retailerSet = new Set(filters.retailers);
    const gearTypeSet = new Set(filters.gearTypes);
    return deals
      .filter((deal) => {
        return (
          deal.retailers.some(({ retailer }) => {
            return retailerSet.has(retailer);
          }) && gearTypeSet.has(deal.itemType)
        );
      })
      .sort((a, b) => {
        if (filters.sortBy === SORT_BY_KEYS.DOLLAR_OFF) {
          const aPrice =
            Number(a.retailers[0].retailPrice) -
            Number(a.retailers[0].salePrice);
          const bPrice =
            Number(b.retailers[0].retailPrice) -
            Number(b.retailers[0].salePrice);
          return bPrice - aPrice;
        }
        return b.biggestSale - a.biggestSale;
      });
  };

  React.useEffect(() => {
    const retailerSet = new Set();
    deals.forEach(({ retailers }) => {
      retailers.forEach(({ retailer }) => retailerSet.add(retailer));
    });

    setAvailableSelections({ retailers: [...retailerSet] });
    setFilters({ ...filters, retailers: [...retailerSet] });
  }, [deals]);

  const handleChangeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredDeals = filterDeals(deals);
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Paper elevation={4} sx={{ padding: "10px 10px 10px 10px" }}>
      <Stack gap={1}>
        <Stack>
          <Typography variant="h5" sx={{ paddingLeft: "5px" }}>
            Daily Deals
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              paddingLeft: "5px",
              color: getColorWithMode(darkMode, "lightShadow"),
            }}
          >
            {`Updated at: ${momenttz(dealDate)
              .tz(timeZoneString)
              .format("ddd MMM DD YYYY @ hh:mm a zz")}`}
          </Typography>
        </Stack>
        <Stack direction="row">
          <FormControl sx={{ m: 1, width: 170 }} size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
              value={filters.sortBy}
              label="Sort By"
              onChange={(e) => handleChangeFilters("sortBy", e.target.value)}
            >
              <MenuItem value={SORT_BY_KEYS.PERCENTAGE_OFF} dense>
                Percentage Off
              </MenuItem>
              <MenuItem value={SORT_BY_KEYS.DOLLAR_OFF} dense>
                Price Difference
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 170 }} size="small">
            <InputLabel>Retailers</InputLabel>
            <Select
              multiple
              value={filters.retailers}
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                handleChangeFilters(
                  "retailers",
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              input={<OutlinedInput label="Retailers" />}
              renderValue={(selected) => `${selected.length} Retailer(s)`}
            >
              {availableSelections.retailers.map((retailer) => (
                <MenuItem key={retailer} value={retailer} dense>
                  <Checkbox
                    checked={filters.retailers.indexOf(retailer) > -1}
                  />
                  <ListItemText
                    sx={{ textTransform: "capitalize" }}
                    primary={retailer}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 170 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              Gear Types
            </InputLabel>
            <Select
              multiple
              value={filters.gearTypes}
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                handleChangeFilters(
                  "gearTypes",
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              input={<OutlinedInput label="Gear Types" />}
              renderValue={(selected) => `${selected.length} Gear Type(s)`}
            >
              {Object.keys(ITEM_TYPE_MAP).map((key) => (
                <MenuItem key={key} value={key} dense>
                  <Checkbox checked={filters.gearTypes.indexOf(key) > -1} />
                  <ListItemText
                    sx={{ textTransform: "capitalize" }}
                    primary={ITEM_TYPE_MAP[key]}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {filteredDeals.length > 0 ? (
          <>
            <Grid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, 220px)", //the width of the card
                justifyContent: "center",
                gridGap: "10px",
              }}
            >
              {filteredDeals.slice(0, maxShow).map((deal, index) => (
                <ItemCard
                  {...deal}
                  darkMode={darkMode}
                  index={index}
                  filters={filters}
                />
              ))}
            </Grid>
            {maxShow < filteredDeals.length ? (
              <Button
                onClick={() => {
                  setMaxShow(maxShow + 12);
                }}
              >
                Show More Deals
              </Button>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ color: getColorWithMode(darkMode, "lightShadow") }}
          >
            No Deals Found
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default DealCarousel;
