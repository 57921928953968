import React from "react";

import { Paper, Stack, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getWeightInUnitFromGrams } from "../utils";
import { useMediaQuery } from "react-responsive";

const OverallSummaryRow = ({
  unit,
  setUnit,
  darkMode,
  isShared,
  weightInGrams,
  price,
  packCurrency,
  hidePrices,
}) => {
  const isMobile = useMediaQuery({ maxWidth: "898px" });

  const getCurrency = (price) => {
    try {
      return Number(price).toLocaleString("en-US", {
        style: "currency",
        currency: packCurrency,
      });
    } catch (e) {
      return Number(price).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  return (
    <Paper
      elevation={5}
      sx={{
        height: isMobile ? "100px" : "70px",
        marginLeft: "15px",
        marginTop: "5px",
        minWidth: isMobile ? "765px" : "835px",
        position: "sticky",
        bottom: "5px",
        paddingBottom: "10px",
        marginBottom: "10px",
        zIndex: "100",
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <TableContainer
          sx={{
            width: isMobile ? "300px" : "260px",
            marginRight: isShared
              ? isMobile
                ? "80px"
                : "120px"
              : isMobile
              ? "40px"
              : "160px",
          }}
        >
          <Table size={isMobile ? "large" : "small"}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: isMobile ? "20px" : "13px",
                    textDecoration: "underline",
                    borderBottom: "0px",
                    paddingBottom: "0px",
                  }}
                  align={hidePrices ? "right" : "center"}
                >
                  Total Weight
                </TableCell>
                {hidePrices ? (
                  <></>
                ) : (
                  <TableCell
                    sx={{
                      fontSize: isMobile ? "20px" : "13px",
                      textDecoration: "underline",
                      borderBottom: "0px",
                      paddingBottom: "0px",
                    }}
                    align="center"
                  >
                    Total Price
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align={hidePrices ? "right" : "center"}
                  sx={{
                    borderBottom: "0px",
                    fontSize: isMobile ? "22px" : "16px",
                    paddingTop: "0px",
                    lineHeight: isMobile ? "40px" : "28px",
                    verticalAlign: "center",
                  }}
                >
                  {getWeightInUnitFromGrams(
                    weightInGrams,
                    unit || "lb"
                  ).toFixed(2)}
                  <FormControl
                    variant="standard"
                    size={isMobile ? "large" : "small"}
                    sx={{
                      marginLeft: "5px",
                      borderBottom: "none",
                      backgroundColor: darkMode ? "#1F1F1F" : "white",
                    }}
                  >
                    <Select
                      value={unit}
                      onChange={(e) => {
                        setUnit(e.target.value);
                      }}
                      disableUnderline
                      sx={{ fontSize: isMobile ? "22px" : "16px" }}
                    >
                      <MenuItem value="g">g</MenuItem>
                      <MenuItem value="oz">oz</MenuItem>
                      <MenuItem value="lb">lb</MenuItem>
                      <MenuItem value="kg">kg</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                {hidePrices ? (
                  <></>
                ) : (
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "0px",
                      fontSize: isMobile ? "22px" : "16px",
                      paddingTop: "0px",
                    }}
                  >
                    <Tooltip
                      title={
                        isShared
                          ? null
                          : "You can update your currency in Pack Settings & Analytics"
                      }
                    >
                      {getCurrency(price)}
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  );
};

export default OverallSummaryRow;
