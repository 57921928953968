import React from "react";

import {
  Box,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { getColorWithMode } from "../constants/colors";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import StorefrontIcon from "@mui/icons-material/Storefront";
import GearComparisonSelectorCollapse from "./GearComparisonSelectorCollapse";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { getUserIsLoggedIn } from "../utils";
import { Backpack } from "lucide-react";

const SubNavBar = ({ darkMode }) => {
  const [browseGearIsOpen, setBrowseGearIsOpen] = React.useState(true);

  return (
    <Container
      sx={{
        height: "50px",
        position: "sticky",
        top: "50px",
        zIndex: 100,
        padding: "0px 0px 0px 0px",
        backgroundColor: getColorWithMode(darkMode, "nav"),
        borderBottom: `0.5px solid ${getColorWithMode(darkMode, "navBottom")}`,
      }}
    >
      <Stack direction="row">
        <Link href="/packs" sx={{ textDecoration: "none" }}>
          <Box
            sx={{
              height: "50px",
              width: "140px",
              textAlign: "center",
              color: getColorWithMode(darkMode, "text"),
              fontFamily: "helvetica",
              fontWeight: "bold",
              "&:hover": { opacity: "0.6", cursor: "pointer" },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Backpack size={18} />
              <Typography sx={{ fontFamily: "helvetica" }}>
                {getUserIsLoggedIn() ? "My Packs" : "Create"}
              </Typography>
            </Stack>
          </Box>
        </Link>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Link href="/browse" sx={{ textDecoration: "none" }}>
          <Box
            sx={{
              height: "50px",
              width: "180px",
              textAlign: "center",
              color: getColorWithMode(darkMode, "text"),
              fontFamily: "helvetica",
              fontWeight: "bold",
              "&:hover": { opacity: "0.6", cursor: "pointer" },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <TravelExploreIcon fontSize="small" />
              <Typography sx={{ fontFamily: "helvetica" }}>
                Browse Packs
              </Typography>
            </Stack>
          </Box>
        </Link>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Box
          sx={{
            height: "50px",
            width: "200px",
            textAlign: "center",
            verticalAlign: "center",
            lineHeight: "50px",
            color: getColorWithMode(darkMode, "text"),
            fontFamily: "helvetica",
            fontWeight: "bold",
            "&:hover": { opacity: "0.6", cursor: "pointer" },
          }}
          onClick={(e) => {
            e.stopPropagation();
            setBrowseGearIsOpen(!browseGearIsOpen);
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <StorefrontIcon fontSize="small" />
            <Typography sx={{ fontFamily: "helvetica" }}>
              Explore Gear
            </Typography>
            <KeyboardArrowDown />
          </Stack>
        </Box>
      </Stack>
      <GearComparisonSelectorCollapse
        darkMode={darkMode}
        browseGearIsOpen={browseGearIsOpen}
        setBrowseGearIsClosed={setBrowseGearIsOpen}
      />
    </Container>
  );
};

export default SubNavBar;
