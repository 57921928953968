import { IconButton, Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

let allSocials = new Set(["FACEBOOK", "TIKTOK", "YOUTUBE", "INSTAGRAM"]);

const SocialLinks = ({
  facebook,
  instagram,
  youtube,
  tiktok,
  spacing,
  socialsToShow,
}) => {
  let socials = socialsToShow ? new Set(socialsToShow) : allSocials;
  return (
    <Stack direction="row" spacing={spacing} alignItems="center">
      {youtube && socials.has("YOUTUBE") ? (
        <IconButton
          size="small"
          onClick={() => {
            let url = youtube;
            if (!url.match(/^https?:\/\//i)) {
              url = "http://" + url;
            }
            window.open(url, "_blank").focus();
          }}
        >
          <YouTubeIcon sx={{ color: "#CD201F" }} />
        </IconButton>
      ) : (
        <></>
      )}
      {facebook && socials.has("FACEBOOK") ? (
        <IconButton
          size="small"
          onClick={() => {
            let url = facebook;
            if (!url.match(/^https?:\/\//i)) {
              url = "http://" + url;
            }
            window.open(url, "_blank").focus();
          }}
        >
          <FacebookIcon sx={{ color: "#316FF6" }} />
        </IconButton>
      ) : (
        <></>
      )}
      {instagram && socials.has("INSTAGRAM") ? (
        <IconButton
          size="small"
          onClick={() => {
            let url = instagram;
            if (!url.match(/^https?:\/\//i)) {
              url = "http://" + url;
            }
            window.open(url, "_blank").focus();
          }}
        >
          <InstagramIcon
            sx={{
              height: "20px",
              width: "20px",
              background:
                "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
              borderRadius: "20%",
            }}
          />
        </IconButton>
      ) : (
        <></>
      )}
      {tiktok && socials.has("TIKTOK") ? (
        <IconButton
          size="small"
          onClick={() => {
            let url = tiktok;
            if (!url.match(/^https?:\/\//i)) {
              url = "http://" + url;
            }
            window.open(url, "_blank").focus();
          }}
        >
          <img
            style={{
              width: "22px",
              height: "22px",
              objectFit: "cover",
              overflow: "hidden",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            alt=""
            src={
              "https://cdn.pixabay.com/photo/2021/06/15/12/28/tiktok-6338429_1280.png"
            }
          ></img>
        </IconButton>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default SocialLinks;
