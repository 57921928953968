import React from "react";
import PackVisualization from "./PackVisualization";
import ItemSuggestion from "./ItemSuggestion";
import { flatMap } from "lodash";
import { getWeightInGrams, getWeightInUnitFromGrams } from "../utils";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, Stack } from "@mui/material";

const getWeightDistributionFromTableData = (tableData) => {
  const rawCategoryDataArray = [];
  const uniqueItemCategories = new Set();
  const { baseInGrams, consumableInGrams, wornInGrams, weightInGrams } =
    tableData.reduce(
      (acc, curr) => {
        const {
          summedRowWeight,
          summedRowBase,
          summedRowWorn,
          summedRowConsumable,
        } = Object.values(curr.rows).reduce(
          (acc, curr) => {
            const isBase = !Boolean(curr.weightType);
            const isWorn = curr.weightType === WEIGHT_TYPES.WORN;
            const isConsumable = curr.weightType === WEIGHT_TYPES.CONSUMABLE;
            uniqueItemCategories.add(curr.category);
            // We only use 1 worn when quantity is 1 or more
            const wornWeight =
              isWorn && curr.quantity > 0
                ? getWeightInGrams(curr.weight, curr.unit)
                : 0;
            const wornWeightForBase =
              isWorn && curr.quantity > 1
                ? getWeightInGrams(curr.weight, curr.unit) * (curr.quantity - 1)
                : 0;
            return {
              summedRowWeight:
                acc.summedRowWeight +
                getWeightInGrams(curr.weight, curr.unit) * curr.quantity,
              summedRowBase: isBase
                ? acc.summedRowBase +
                  getWeightInGrams(curr.weight, curr.unit) * curr.quantity
                : acc.summedRowBase + wornWeightForBase,

              summedRowWorn: isWorn
                ? acc.summedRowWorn + wornWeight
                : acc.summedRowWorn,
              summedRowConsumable: isConsumable
                ? acc.summedRowConsumable +
                  getWeightInGrams(curr.weight, curr.unit) * curr.quantity
                : acc.summedRowConsumable,
            };
          },
          {
            summedRowWeight: 0,
            summedRowBase: 0,
            summedRowWorn: 0,
            summedRowConsumable: 0,
          }
        );
        rawCategoryDataArray.push({
          item: curr.title,
          weight: getWeightInUnitFromGrams(
            summedRowWeight,
            curr.summaryRowUnit || "oz"
          ),
          unit: curr.summaryRowUnit || "oz",
          quantity: 1,
          type: "summary",
        });
        return {
          weightInGrams: acc.weightInGrams + summedRowWeight,
          baseInGrams: acc.baseInGrams + summedRowBase,
          wornInGrams: acc.wornInGrams + summedRowWorn,
          consumableInGrams: acc.consumableInGrams + summedRowConsumable,
        };
      },
      {
        baseInGrams: 0,
        consumableInGrams: 0,
        wornInGrams: 0,
        weightInGrams: 0,
      }
    );
  const sortedCategoryDataArray = rawCategoryDataArray
    .filter(({ weight }) => weight > 0)
    .sort(
      (a, b) =>
        getWeightInGrams(b.weight, b.unit) - getWeightInGrams(a.weight, a.unit)
    );
  const categoryLabelArray = sortedCategoryDataArray.map(({ item }) => item);
  return {
    totalWeightsInGrams: {
      baseInGrams,
      consumableInGrams,
      wornInGrams,
      weightInGrams,
    },
    sortedCategoryDataArray,
    sortedCategoryLabelArray: categoryLabelArray,
    uniqueItemCategories: [...uniqueItemCategories],
  };
};

const SideContainer = ({
  tableData,
  unit,
  toggleState,
  setToggleState,
  packIsLoading,
  setSideNavIsOpen,
  darkMode,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });

  const tableRows = tableData.map((table) => Object.values(table.rows));
  const flatRows = flatMap(tableRows);
  const filteredRows = flatRows.filter(
    ({ weight, quantity }) => weight * quantity !== 0
  );
  const sortedData = filteredRows.sort(
    (a, b) =>
      getWeightInGrams(b.weight, b.unit) * b.quantity -
      getWeightInGrams(a.weight, a.unit) * a.quantity
  );
  const {
    uniqueItemCategories,
    totalWeightsInGrams,
    sortedCategoryDataArray,
    sortedCategoryLabelArray,
  } = getWeightDistributionFromTableData(tableData);

  const [labels, setLabels] = React.useState(
    sortedData.map(({ item }) => item)
  );
  const [dataArray, setDataArray] = React.useState(sortedData);
  const [categoryDataArray, setCategoryDataArray] = React.useState(
    sortedCategoryDataArray
  );
  const [categoryLabelArray, setCategoryLabelArray] = React.useState(
    sortedCategoryLabelArray
  );
  const [weightsInGrams, setWeightsInGrams] =
    React.useState(totalWeightsInGrams);
  const [itemCategoryList, setItemCategoryList] =
    React.useState(uniqueItemCategories);
  const [showingVisualization, setShowingVisualization] = React.useState(true);

  const stringifiedTableData = tableData.reduce((acc, curr) => {
    acc += curr.title;
    Object.values(curr.rows).forEach(
      ({ unit, weight, quantity, weightType }) => {
        acc += [unit, weight, quantity, weightType].join("_");
      }
    );
    return acc;
  }, "");

  useEffect(() => {
    const tableRows = tableData.map((table) => Object.values(table.rows));
    const flatRows = flatMap(tableRows);
    const filteredRows = flatRows.filter(
      ({ weight, quantity }) => weight * quantity !== 0
    );
    const sortedData = filteredRows.sort(
      (a, b) =>
        getWeightInGrams(b.weight, b.unit) * b.quantity -
        getWeightInGrams(a.weight, a.unit) * a.quantity
    );

    const {
      totalWeightsInGrams,
      sortedCategoryDataArray,
      sortedCategoryLabelArray,
      uniqueItemCategories,
    } = getWeightDistributionFromTableData(tableData);

    setLabels(sortedData.map(({ item }) => item));
    setDataArray(sortedData);
    setCategoryDataArray(sortedCategoryDataArray);
    setCategoryLabelArray(sortedCategoryLabelArray);
    setWeightsInGrams(totalWeightsInGrams);
    setItemCategoryList(uniqueItemCategories);
  }, [stringifiedTableData]);

  return (
    <Stack alignItems="center">
      {showingVisualization ? (
        <PackVisualization
          labels={labels}
          packDataArray={dataArray}
          unit={unit}
          weightsInGrams={weightsInGrams}
          categoryDataArray={categoryDataArray}
          categoryLabelArray={categoryLabelArray}
          toggleState={toggleState}
          setToggleState={setToggleState}
          packIsLoading={packIsLoading}
          isDesktopOrLaptop={isDesktopOrLaptop}
          setSideNavIsOpen={setSideNavIsOpen}
        />
      ) : (
        <ItemSuggestion
          tableData={tableData}
          weightsInGrams={weightsInGrams}
          packIsLoading={packIsLoading}
          itemCategoryList={itemCategoryList}
          isDesktopOrLaptop={isDesktopOrLaptop}
          darkMode={darkMode}
        />
      )}
      {packIsLoading ? (
        <></>
      ) : (
        <Stack direction="row" justifyContent="space-between">
          <Button
            size="small"
            onClick={() => {
              setShowingVisualization(!showingVisualization);
            }}
          >
            {showingVisualization ? "Show Gear Suggestions" : "Show Pack Stats"}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default SideContainer;
