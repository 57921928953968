import AffiliateMap from "../constants/affiliates";
import { logUserInteraction } from "../api/logging";

export const externalLinkWrapper = (url, affiliateLinksAreOff) => {
  console.log(url);
  try {
    if (affiliateLinksAreOff) {
      const formattedUrl = url.slice(0, 4) !== "http" ? `http://${url}` : url;
      window.open(formattedUrl, "_blank").focus();
      return;
    }
    let formattedUrl = url.slice(0, 4) !== "http" ? `http://${url}` : url;
    const domain = new URL(formattedUrl);
    const finalDomain = domain.hostname
      .replaceAll("www.", "")
      .split(".")[0]
      .toLowerCase();
    const affiliateKey = Object.keys(AffiliateMap).find((key) => {
      return AffiliateMap[key].domain === finalDomain;
    });
    logUserInteraction("linkClick", affiliateKey);
    if (!affiliateKey) {
      window.open(formattedUrl, "_blank").focus();
      return;
    }
    const { baseUrl, rule, stripFunction } = AffiliateMap[affiliateKey];
    if (rule === "prependUrl") {
      formattedUrl = formattedUrl.split("?")[0];
      const affUrl = `${formattedUrl}${baseUrl}`;
      window.open(affUrl, "_blank").focus();
      return;
    }
    if (rule === "appendUrl") {
      formattedUrl = formattedUrl.split("?")[0];
      const affUrl = `${baseUrl}${encodeURIComponent(formattedUrl)}`;
      window.open(affUrl, "_blank").focus();
      return;
    }
    if (rule === "stripAndPrependUrl") {
      const strippedUrl = stripFunction(url);
      const affUrl = `${strippedUrl}${baseUrl}`;
      window.open(affUrl, "_blank").focus();
      return;
    }
    window.open(formattedUrl, "_blank").focus();
  } catch (error) {
    console.log(error);
    const formattedUrl = url.slice(0, 4) !== "http" ? `http://${url}` : url;
    window.open(formattedUrl, "_blank").focus();
  }
};

export const getAffiliateKey = (url) => {
  if (!url) return null;
  let formattedUrl = url.slice(0, 4) !== "http" ? `http://${url}` : url;
  const domain = new URL(formattedUrl);
  const finalDomain = domain.hostname
    .replaceAll("www.", "")
    .split(".")[0]
    .toLowerCase();
  const affiliateKey = Object.keys(AffiliateMap).find((key) => {
    return AffiliateMap[key].domain === finalDomain;
  });
  return affiliateKey;
};
