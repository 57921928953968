import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { Chip, Link, Stack, Tooltip, Typography } from "@mui/material";
import { DURATION_SETTINGS, SEASON_SETTINGS } from "../constants/packSettings";
import { getFormattedWeightInUnit, getLastUpdatedAtText } from "../utils";

const PackExploreTable = ({
  darkMode,
  userPacks,
  useMetric,
  upvotedIds,
  downvotedIds,
  sortModel,
  setSortModel,
}) => {
  const getColorForUpvote = (packIsUpvoted, packIsDownvoted) => {
    if (packIsUpvoted) return darkMode ? "#4caf50" : "#1b5e20";
    if (packIsDownvoted) return darkMode ? "#ff9800" : "#e65100";
    return "";
  };

  const getPrice = (price, currency) => {
    try {
      return Number(price).toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      });
    } catch (e) {
      return Number(price).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  const columns = React.useMemo(() => {
    return [
      {
        field: "voteCount",
        headerName: "Votes",
        editable: false,
        headerAlign: "center",
        headerClassName: "firstcolumnSeparator",
        sortable: false,
        width: 70,
        disableColumnMenu: true,
        renderCell: (params) => {
          const packIsUpvoted = upvotedIds.includes(params.row.id);
          const packIsDownvoted = downvotedIds.includes(params.row.id);
          return (
            <Stack alignItems="center" sx={{ width: "100%" }}>
              <Box
                sx={{
                  color: getColorForUpvote(packIsUpvoted, packIsDownvoted),
                }}
              >
                <Typography>{params.row.voteCount}</Typography>
              </Box>
            </Stack>
          );
        },
      },
      {
        field: "packName",
        headerName: "Name",
        editable: false,
        headerAlign: "left",
        minWidth: 200,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Stack
            sx={{
              padding: "0px 0px 0px 0px",
              width: "100%",
              minHeight: "85px",
              paddingTop: "30px",
            }}
          >
            <Stack spacing={0.5}>
              <Tooltip title={params.row.packName}>
                <Link
                  href={`/s/${params.row.shareableLink}`}
                  sx={{
                    textDecoration: "none",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    "&:hover": { opacity: "0.6", cursor: "pointer" },
                  }}
                >
                  {params.row.packName}
                </Link>
              </Tooltip>
              <Link
                href={`/user/${params.row.creatorUsername}`}
                sx={{
                  textDecoration: "none",
                  color: "grey",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  "&:hover": { opacity: "0.6", cursor: "pointer" },
                }}
              >
                {`@${params.row.creatorUsername}`}
              </Link>
            </Stack>
          </Stack>
        ),
        flex: 1,
      },
      {
        field: "weight",
        headerName: "Base Weight",
        width: 130,
        editable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) =>
          params.row.weight ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ paddingTop: "18%" }}
            >
              <Box>
                {getFormattedWeightInUnit(params.row.weight, useMetric)}
              </Box>
              <Box
                sx={{ color: "grey" }}
              >{`${params.row.itemCount} items`}</Box>
            </Stack>
          ) : (
            "-"
          ),
      },
      {
        field: "price",
        headerName: "Price",
        width: 90,
        editable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) =>
          params.row.price
            ? getPrice(params.row.price, params.row.currency)
            : "-",
      },
      {
        field: "trail",
        headerName: "Trail/Location",
        width: 160,
        editable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) => (
          <Tooltip
            title={
              params.row.trail
                ? params.row.trail
                : "No Trail/Location Specified"
            }
          >
            <Typography
              variant="subtitle2"
              sx={{
                maxWidth: "160px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.trail ? params.row.trail : "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "duration",
        headerName: "Duration",
        width: 90,
        editable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) =>
          params.row.duration ? DURATION_SETTINGS[params.row.duration] : "-",
      },
      {
        field: "season",
        headerName: "Season",
        width: 90,
        editable: false,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) =>
          params.row.season ? SEASON_SETTINGS[params.row.season] : "-",
      },
      {
        field: "tags",
        headerName: "Tags",
        editable: false,
        headerAlign: "center",
        align: "left",
        sortable: false,
        minWidth: 150,
        flex: 1,
        headerClassName: "lastcolumnSeparator",
        disableColumnMenu: true,
        renderCell: (params) => {
          const chips = params.row.tags
            .map((tag) =>
              tag && tag.length > 0 ? (
                <Chip
                  key={tag}
                  size="small"
                  color="success"
                  variant="outlined"
                  label={tag}
                  sx={{ margin: "1px" }}
                />
              ) : null
            )
            .filter((chip) => chip);
          return (
            <Stack
              spacing={0}
              justifyContent="space-between"
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Stack
                direction="row"
                flexWrap="wrap"
                alignContent={"center"}
                sx={{
                  maxWidth: "100%",
                  minWidth: "100px",
                  marginTop: "10px",
                  height: "100%",
                }}
              >
                {chips}
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                }}
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: "grey" }}
                >{`Last Updated: ${getLastUpdatedAtText(
                  params.row.lastUpdated
                )}`}</Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        field: "lastUpdated",
        hide: true,
      },
    ];
  }, [useMetric, upvotedIds, downvotedIds, darkMode]);

  return (
    <DataGridPro
      sx={{
        width: "100%",
      }}
      autoHeight
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      rows={userPacks}
      columns={columns}
      disableSelectionOnClick
      pagination
      getRowHeight={() => "auto"}
      components={{
        NoRowsOverlay: () => (
          <Box
            sx={{
              textAlign: "center",
              verticalAlign: "center",
              lineHeight: "72px",
              color: "#999",
            }}
          >
            No Items
          </Box>
        ),
      }}
    />
  );
};

export default PackExploreTable;
