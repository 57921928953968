import React from "react";
import { useMediaQuery } from "react-responsive";

import { Container, Divider, Paper } from "@mui/material";
import PackSelector from "./PackSelector";
import Button from "@mui/material/Button";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Stack from "@mui/material/Stack";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SharedPackDisplay from "./SharedPackDisplay";
import Collapse from "@mui/material/Collapse";
import { getColorWithMode } from "../constants/colors";

const SideNav = ({
  tableId,
  setTableId,
  setTableData,
  packName,
  setPackName,
  defaultPackId,
  setDefaultPackId,
  setImportDialogIsOpen,
  setGearClosetIsOpen,
  setPackDescription,
  setPackIsLoading,
  packIsLoading,
  handleSelectNewPack,
  isSideNavOpen,
  darkMode,
  setPackSettingsTags,
  setPackAnalytics,
  setPackVisibility,
  setAffiliateLinksAreOff,
  setPackCurrency,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });

  const sideNavIsOpen = isSideNavOpen || isDesktopOrLaptop;
  return (
    <Collapse
      in={sideNavIsOpen}
      orientation="horizontal"
      sx={{ minWidth: sideNavIsOpen ? "240px !important" : "" }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: getColorWithMode(darkMode, "nav"),
          borderRadius: "0px",
          width: "240px",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            height: "100svh",
            position: "sticky",
            top: "0px",
            marginBottom: "50px",
          }}
        >
          <img
            src="/pack_wizard.png"
            onClick={() => {
              window.location = `${window.location.origin}?navigatedToHome=true`;
            }}
            className="packshrink-logo"
            alt="Pack Wizard"
          />
          <Container sx={{ marginTop: "20px" }}>
            <Stack gap={1} alignItems="center">
              <PackSelector
                tableId={tableId}
                packName={packName}
                setTableId={setTableId}
                setTableData={setTableData}
                setPackName={setPackName}
                setPackDescription={setPackDescription}
                defaultPackId={defaultPackId}
                setDefaultPackId={setDefaultPackId}
                setPackIsLoading={setPackIsLoading}
                packIsLoading={packIsLoading}
                handleSelectNewPack={handleSelectNewPack}
                darkMode={darkMode}
                setPackSettingsTags={setPackSettingsTags}
                setPackAnalytics={setPackAnalytics}
                setPackVisibility={setPackVisibility}
                setAffiliateLinksAreOff={setAffiliateLinksAreOff}
                setPackCurrency={setPackCurrency}
              />
              <Button
                fullWidth
                variant={darkMode ? "outlined" : "contained"}
                color="success"
                startIcon={<ImportExportIcon />}
                onClick={() => {
                  setImportDialogIsOpen(true);
                }}
              >
                Import Pack
              </Button>
              <Divider width="100%" sx={{ marginTop: "10px" }} />
              <Button
                sx={{ marginTop: "10px" }}
                fullWidth
                variant={darkMode ? "outlined" : "contained"}
                color="info"
                startIcon={<MeetingRoomIcon />}
                onClick={() => {
                  setGearClosetIsOpen(true);
                }}
              >
                Gear Closet
              </Button>
            </Stack>
          </Container>
          <SharedPackDisplay darkMode={darkMode} />
        </Stack>
      </Paper>
    </Collapse>
  );
};

export default SideNav;
