import React from "react";

import Stack from "@mui/material/Stack";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DynamicArticleGenerator from "../Articles/DynamicArticleGenerator";
import momenttz from "moment-timezone";
import CodeIcon from "@mui/icons-material/Code";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AutocompleteByGearType from "./AutocompleteByGearType";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { debounce } from "lodash";
import uniqid from "uniqid";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  createArticle,
  getArticlesforSelection,
  fetchArticleForEditing,
  updateArticleEdits,
  publishEdits,
  unpublishArticle,
} from "../../utils";
import MODULE_KEYS from "../Articles/constants/moduleKeys.json";
import FORMAT_KEYS from "../Articles/constants/formatKeys.json";
import {
  SEO_MODULE,
  GEAR_PIECE_MODULE,
  HTML_MODULE,
  INTRO_MODULE,
  GEAR_FILTER_MODULE,
} from "../Articles/constants/moduleTypes";
import { buildArticleData } from "../Articles/utils";
import ArticleGearFilter from "../Articles/ArticleGearFilter";

const formatDate = (isoString) => {
  const date = new Date(isoString);

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);

  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)

  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${month}/${day}/${year}, ${hours}:${minutes}${ampm}`;
};

const fieldFetcher = (field, params) => {
  switch (field.format) {
    case FORMAT_KEYS.TEXT:
      return (
        <TextField
          key={`${params.moduleId}-${field.key}`}
          label={field.label}
          size="small"
          fullWidth
          value={field.value}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            params.customHandler(field.key, event.target.value, params);
          }}
        />
      );
    case FORMAT_KEYS.URL:
      return (
        <TextField
          key={`${params.moduleId}-${field.key}`}
          label={field.label}
          size="small"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={field.value}
          onChange={(event) => {
            params.customHandler(field.key, event.target.value, params);
          }}
        />
      );
    case FORMAT_KEYS.RAW_HTML:
      return (
        <TextField
          key={`${params.moduleId}-${field.key}`}
          multiline
          label={field.label}
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={field.value}
          onChange={(event) => {
            params.customHandler(field.key, event.target.value, params);
          }}
        />
      );
    case FORMAT_KEYS.GEAR_TYPE:
      return (
        <FormControl
          sx={{ width: "200px" }}
          key={`${params.moduleId}-${field.key}`}
        >
          <InputLabel>{field.label}</InputLabel>
          <Select
            size="small"
            label={field.label}
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={(event) => {
              params.customHandler(field.key, event.target.value, params);
            }}
          >
            <MenuItem value="sleepingPad">Sleeping Pads</MenuItem>
            <MenuItem value="sleepingBag">Sleeping Bag/Quilt</MenuItem>
            <MenuItem value="tent">Tent</MenuItem>
            <MenuItem value="backpack">Pack</MenuItem>
            <MenuItem value="insulatedJacket">Insulated Jacket</MenuItem>
          </Select>
        </FormControl>
      );
    case FORMAT_KEYS.GEAR_ID:
      return (
        <AutocompleteByGearType
          key={`${params.moduleId}-${field.key}`}
          gearType={params.gearType}
          disabled={params.isDisabled}
          gearId={field.value}
          changeHandler={(value) => {
            params.customHandler(field.key, value, params);
          }}
        />
      );
    case FORMAT_KEYS.INTEGER:
      return (
        <TextField
          size="small"
          key={params.moduleId}
          label={field.label}
          InputLabelProps={{ shrink: true }}
          type="number"
          value={field.value}
          onChange={(event) => {
            params.customHandler(field.key, event.target.value, params);
          }}
        />
      );
    case FORMAT_KEYS.GEAR_FILTER:
      return (
        <ArticleGearFilter
          key={params.moduleId}
          value={field.value}
          gearType={params.gearType}
          onChange={(value) => {
            params.customHandler(field.key, value, params);
          }}
        />
      );
  }
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ArticleBuilderContainer = ({ isDarkMode }) => {
  const [seoData, setSEOData] = React.useState({
    title: "",
    path: "",
    description: "",
    image: "",
    keywords: "",
  });
  const [introData, setIntroData] = React.useState({
    date: momenttz()
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("MMM DD YYYY"),
    gearType: "",
    title: "",
    subtitle: "",
    headerImage: "",
  });
  const [articleDynamicData, setArticleDynamicData] = React.useState([]);
  const [preparedArticleData, setPreparedArticleData] = React.useState(
    buildArticleData(seoData, introData, articleDynamicData)
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moduleKey, setModuleKey] = React.useState(0);
  const [articlesForSelection, setArticlesForSelection] = React.useState([]);
  const [articleId, setArticleId] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isPublished, setIsPublished] = React.useState(false);
  const [notAdmin, setNotAdmin] = React.useState(true);

  const handleOpenAddModule = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseAddModule = () => {
    setAnchorEl(null);
  };

  const handleUpdateSEO = (key, value) => {
    setSEOData({ ...seoData, [key]: value });
    if (!isSaving) setIsSaving(true);
    debouncedUpdateArticle(
      articleId,
      seoData,
      { ...seoData, [key]: value },
      articleDynamicData
    );
  };

  const handleMove = (currentIndex, moveUp) => {
    let moveVal = moveUp ? -1 : 1;
    let newIndex = currentIndex + moveVal;
    if (newIndex >= 0 && newIndex <= articleDynamicData.length) {
      const newOrder = reorder(articleDynamicData, currentIndex, newIndex);
      setArticleDynamicData(newOrder);
    }
  };

  const handleUpdateIntroData = (key, value) => {
    setIntroData({ ...introData, [key]: value });
    if (!isSaving) setIsSaving(true);
    debouncedUpdateArticle(
      articleId,
      seoData,
      { ...introData, [key]: value },
      articleDynamicData
    );
  };

  const defaultHandler = (key, value, { parentIndex, childIndex }) => {
    const newStateData = [...articleDynamicData];
    const moduleToUpdate = newStateData[parentIndex];
    const moduleChildren = moduleToUpdate.children;
    moduleChildren[childIndex].value = value;
    newStateData[parentIndex].children = moduleChildren;
    setArticleDynamicData(newStateData);
    if (!isSaving) setIsSaving(true);
    debouncedUpdateArticle(articleId, seoData, introData, newStateData);
  };

  const handleAddAdditionalHTMLModule = () => {
    setArticleDynamicData([
      ...articleDynamicData,
      JSON.parse(JSON.stringify({ ...HTML_MODULE, id: uniqid() })),
    ]);
  };

  const handleAddGearCardModule = () => {
    setArticleDynamicData([
      ...articleDynamicData,
      JSON.parse(JSON.stringify({ ...GEAR_PIECE_MODULE, id: uniqid() })),
    ]);
  };

  const handleAddFilteredGearModule = () => {
    setModuleKey(moduleKey + 1);
    setArticleDynamicData([
      ...articleDynamicData,
      JSON.parse(JSON.stringify({ ...GEAR_FILTER_MODULE, id: uniqid() })),
    ]);
  };

  const handleAddModule = (moduleType) => {
    setModuleKey(moduleKey + 1);
    switch (moduleType) {
      case MODULE_KEYS.RAW_HTML:
        handleAddAdditionalHTMLModule();
        break;
      case MODULE_KEYS.GEAR_CARD:
        handleAddGearCardModule();
        break;
      case MODULE_KEYS.FILTERED_GEAR:
        handleAddFilteredGearModule();
        break;
    }
    handleCloseAddModule();
  };

  const handleDeleteModule = (parentIndex) => {
    const newValues = JSON.parse(JSON.stringify(articleDynamicData));
    newValues.splice(parentIndex, 1);
    setArticleDynamicData(newValues);
    if (!isSaving) setIsSaving(true);
    debouncedUpdateArticle(articleId, seoData, introData, articleDynamicData);
  };

  const handleCreateNewArticle = async () => {
    await createArticle();
    debouncedUpdateArticle.cancel();
    window.location.reload();
  };

  const handleOnChangeArticle = async (e) => {
    const selectedArticleId = e.target.value;
    const article = await fetchArticleForEditing(selectedArticleId);
    if (article) {
      setArticleId(selectedArticleId);
      setNewArticle(article);
    }
  };

  const debouncedUpdateArticle = React.useCallback(
    debounce((articleId, seoData, introData, articleDynamicData) => {
      setIsSaving(false);
      updateArticleEdits(articleId, {
        seo: seoData,
        baseData: introData,
        data: articleDynamicData,
      });
    }, 5000),
    []
  );

  const handlePublish = async () => {
    const published = await publishEdits(articleId);
    setIsPublished(true);
  };

  const handleUnpublish = async () => {
    const published = await unpublishArticle(articleId);
    setIsPublished(false);
  };

  const setNewArticle = (article) => {
    const newSeo = {
      title: "",
      path: "",
      description: "",
      image: "",
      keywords: "",
      ...article.seo,
    };

    const newBaseData = {
      date: momenttz()
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("MMM DD YYYY"),
      gearType: article.gearType || "",
      title: article.title || "",
      subtitle: article.subtitle || "",
      headerImage: article.headerImage || "",
      ...article.baseData,
    };

    setArticleDynamicData(article.data);
    setSEOData(newSeo);
    setIntroData(newBaseData);
  };

  React.useEffect(() => {
    setPreparedArticleData(
      buildArticleData(seoData, introData, articleDynamicData)
    );
  }, [seoData, introData, articleDynamicData]);

  React.useEffect(() => {
    (async () => {
      const articles = await getArticlesforSelection();
      if (articles.error) {
        setNotAdmin(true);
      } else {
        setNotAdmin(false);
      }
      setArticlesForSelection(articles);
      if (articles.length) {
        setArticleId(articles[0]._id);
        const article = await fetchArticleForEditing(articles[0]._id);
        if (article) {
          setNewArticle(article);
          setIsPublished(article.isPublished);
        }
      }
    })();
  }, []);

  return notAdmin ? (
    <Container sx={{ textAlign: "center" }}>
      You do not have permission to see this
    </Container>
  ) : (
    <>
      <Container sx={{ width: "1440px" }}>
        <Stack
          direction="row"
          sx={{ padding: "10px 10px 10px 10px" }}
          justifyContent="space-between"
          gap={2}
        >
          <Stack direction="row">
            <Select
              size="small"
              sx={{ width: "300px" }}
              onChange={handleOnChangeArticle}
              value={articleId}
            >
              {articlesForSelection.map(({ _id, title, updatedAt }) => {
                return (
                  <MenuItem key={_id} value={_id}>
                    <Typography>{`${title} - `}</Typography>
                    <Typography variant="subtitle1" sx={{ color: "grey" }}>
                      {`(${formatDate(updatedAt)})`}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              color="success"
              disabled={isSaving}
              onClick={handleCreateNewArticle}
            >
              Create New Article
            </Button>
          </Stack>
          <Stack>
            <Button
              variant="contained"
              onClick={handlePublish}
              disabled={isSaving}
            >
              {!isSaving ? "Publish Edits" : "Saving Edits"}
            </Button>
            <Typography
              variant="subtitle"
              color={isPublished ? "green" : "yellow"}
            >
              {isPublished
                ? "This Article is Live"
                : "This Article Has Not Been Published"}
              {isPublished && (
                <Button size="small" color="error" onClick={handleUnpublish}>
                  Unpublish?
                </Button>
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Stack gap={1}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{SEO_MODULE.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  {SEO_MODULE.children.map((child) =>
                    fieldFetcher(
                      { ...child, value: seoData[child.key] },
                      { customHandler: handleUpdateSEO }
                    )
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{INTRO_MODULE.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  {INTRO_MODULE.children.map((child) =>
                    fieldFetcher(
                      {
                        ...child,
                        value: introData[child.key],
                      },
                      {
                        customHandler: handleUpdateIntroData,
                      }
                    )
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
            {articleDynamicData.map((module, parentIndex) => (
              <Stack direction="row">
                <Stack>
                  <Button
                    disabled={parentIndex === 0}
                    size="small"
                    onClick={() => {
                      handleMove(parentIndex, true);
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </Button>
                  <Button
                    disabled={parentIndex === articleDynamicData.length - 1}
                    size="small"
                    onClick={() => {
                      handleMove(parentIndex, false);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </Button>
                </Stack>
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">{module.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack gap={2}>
                      {module.children.map((child, childIndex) => {
                        let isGearId = false;
                        let gearType;
                        if (
                          child.format === FORMAT_KEYS.GEAR_ID ||
                          child.format === FORMAT_KEYS.GEAR_FILTER
                        ) {
                          gearType = module.children[childIndex - 1].value;
                          isGearId = true;
                        }
                        return (
                          <Box key={`${module.id}-${childIndex}`}>
                            {fieldFetcher(child, {
                              parentIndex,
                              moduleId: module.id,
                              childIndex,
                              customHandler: defaultHandler,
                              gearType,
                              isDisabled: !gearType && isGearId,
                            })}
                          </Box>
                        );
                      })}
                    </Stack>
                  </AccordionDetails>
                  <AccordionActions>
                    <Button
                      color="error"
                      onClick={() => {
                        handleDeleteModule(parentIndex);
                      }}
                    >
                      Delete Module
                    </Button>
                  </AccordionActions>
                </Accordion>
              </Stack>
            ))}
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="contained"
                disableElevation
                onClick={handleOpenAddModule}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ width: "150px" }}
              >
                Add Module
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseAddModule}
              >
                <MenuItem
                  onClick={() => {
                    handleAddModule(MODULE_KEYS.RAW_HTML);
                  }}
                  disableRipple
                >
                  <CodeIcon />
                  HTML
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleAddModule(MODULE_KEYS.GEAR_CARD);
                  }}
                  disableRipple
                >
                  <CodeIcon />
                  Gear Card
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleAddModule(MODULE_KEYS.FILTERED_GEAR);
                  }}
                  disableRipple
                >
                  <CodeIcon />
                  Filtered Gear List
                </MenuItem>
              </Menu>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Typography variant="h4" textAlign="center">
        Preview
      </Typography>
      <DynamicArticleGenerator
        inputArticleData={{ ...preparedArticleData }}
        isDarkMode={isDarkMode}
      />
    </>
  );
};

export default ArticleBuilderContainer;
