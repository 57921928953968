import { Container, Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import React from "react";
import { getColorWithMode } from "../constants/colors";
import { fetchAdminData } from "../utils";
import { Button, Link, Paper } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { interpolateColors } from "../utils/colorGenerator";
import { interpolateTurbo } from "d3-scale-chromatic";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getFormattedLabelFromKey = (key) => {
  if (key === "signup") return "Sign Ups";
  if (key === "session") return "Sessions";
  if (key === "linkClick") return "Link Clicks";
  if (key === "import") return "Imports";
};

const colorScale = interpolateTurbo;
const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: true,
};
const colors = interpolateColors(7, colorScale, colorRangeInfo);
const linkClickColors = interpolateColors(20, colorScale, colorRangeInfo);

const SESSION_DATA_KEY_BASE = {
  home: {
    label: "Home Page Visits",
    data: [],
    monthlyData: [],
    monthlyValue: 0,
    backgroundColor: colors[0],
    borderColor: colors[0],
  },
  pack: {
    label: "Pack Visits",
    data: [],
    monthlyData: [],
    monthlyValue: 0,
    backgroundColor: colors[1],
    borderColor: colors[1],
  },
  explorer: {
    label: "Pack Explorer Visits",
    data: [],
    monthlyData: [],
    monthlyValue: 0,
    backgroundColor: colors[2],
    borderColor: colors[2],
  },
  gear: {
    label: "Gear Page Visits",
    data: [],
    monthlyData: [],
    monthlyValue: 0,
    backgroundColor: colors[3],
    borderColor: colors[3],
  },
  shared: {
    label: "Shared Pack Visits",
    data: [],
    monthlyData: [],
    monthlyValue: 0,
    backgroundColor: colors[4],
    borderColor: colors[4],
  },
  deals: {
    label: "Deal Visits",
    data: [],
    monthlyData: [],
    monthlyValue: 0,
    backgroundColor: colors[5],
    borderColor: colors[5],
  },
  article: {
    label: "Article Visits",
    data: [],
    monthlyData: [],
    monthlyValue: 0,
    backgroundColor: colors[6],
    borderColor: colors[6],
  },
};

const getMultiLineDataForLinkClicks = (timeline, isDaily) => {
  const labels = timeline.dates;
  let currentMonth = timeline.dates[0].slice(0, 7);
  const labelsForMonthly = [currentMonth];
  let dayCount = 0;
  let monthCount = 0;
  const { data: datasets } = timeline.dates.reduce(
    (acc, date) => {
      const monthKey = date.slice(0, 7);
      const dataForDate = timeline[date].linkClick;
      const seenKeys = new Set();
      if (dataForDate && dataForDate.length > 0) {
        dataForDate.forEach(({ value, count }) => {
          let dailyValue = count ? count : 0;
          if (!value) {
            seenKeys.add("generic");
            acc.data.generic.data.push(dailyValue);
            acc.data.generic.monthlyValue =
              acc.data.generic.monthlyValue + dailyValue;
          } else {
            seenKeys.add(value);
            if (acc.data[value]) {
              acc.data[value].data.push(dailyValue);
              acc.data[value].monthlyValue =
                acc.data[value].monthlyValue + dailyValue;
            } else {
              acc.highestIndex = acc.highestIndex + 1;
              acc.data[value] = {
                label: value,
                data: [...Array(dayCount).fill(0), dailyValue],
                monthlyData: [...Array(monthCount).fill(0), dailyValue],
                monthlyValue: dailyValue,
                backgroundColor: linkClickColors[acc.highestIndex],
                borderColor: linkClickColors[acc.highestIndex],
              };
            }
          }
          if (currentMonth !== monthKey) {
            monthCount++;
            currentMonth = monthKey;
            labelsForMonthly.push(currentMonth);
            Object.keys(acc.data).forEach((key) => {
              acc.data[key].monthlyData.push(acc.data[key].monthlyValue);
              acc.data[key].monthlyValue = 0;
            });
          }
        });
      }
      Object.keys(acc.data).forEach((key) => {
        if (!seenKeys.has(key)) {
          acc.data[key].data.push(0);
        }
      });
      dayCount++;
      return acc;
    },
    {
      data: {
        generic: {
          label: "Unattributed Clicks",
          data: [],
          monthlyData: [],
          monthlyValue: 0,
          backgroundColor: linkClickColors[0],
          borderColor: linkClickColors[0],
        },
      },
      highestIndex: 0,
    }
  );
  Object.keys(datasets).forEach((key) => {
    datasets[key].monthlyData.push(datasets[key].monthlyValue);
    if (!isDaily) datasets[key].data = datasets[key].monthlyData;
  });
  const finalData = {
    labels: isDaily ? labels : labelsForMonthly,
    datasets: Object.values(datasets),
  };
  return finalData;
};

const getMultiLineDataSetFromSessions = (timeline, isDaily) => {
  const labels = timeline.dates;
  let currentMonth = timeline.dates[0].slice(0, 7);
  const labelsForMonthly = [currentMonth];
  const sessionDataKeys = Object.keys(SESSION_DATA_KEY_BASE);
  const datasets = timeline.dates.reduce((acc, date, index) => {
    const monthKey = date.slice(0, 7);
    const dataForDate = timeline[date].session.reduce((acc, curr) => {
      acc[curr.value] = curr.count;
      return acc;
    }, {});
    sessionDataKeys.forEach((key) => {
      let dailyValue = 0;
      if (dataForDate[key]) {
        dailyValue = dataForDate[key];
      }
      if (monthKey === currentMonth) {
        acc[key].monthlyValue = dailyValue + acc[key].monthlyValue;
      }
      acc[key].data.push(dailyValue);
    });
    if (currentMonth !== monthKey) {
      currentMonth = monthKey;
      labelsForMonthly.push(currentMonth);
      sessionDataKeys.forEach((key) => {
        acc[key].monthlyData.push(acc[key].monthlyValue);
        acc[key].monthlyValue = 0;
      });
    }
    return acc;
  }, JSON.parse(JSON.stringify(SESSION_DATA_KEY_BASE)));
  sessionDataKeys.forEach((key) => {
    datasets[key].monthlyData.push(datasets[key].monthlyValue);
    if (!isDaily) datasets[key].data = datasets[key].monthlyData;
  });

  const finalData = {
    labels: isDaily ? labels : labelsForMonthly,
    datasets: Object.values(datasets),
  };
  return finalData;
};

const AdminContainer = ({ darkMode }) => {
  const [data, setData] = React.useState(null);
  const [isDaily, setIsDaily] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const adminData = await fetchAdminData();
      if (!adminData.error) {
        setData(adminData);
      } else {
        setData(null);
      }
    })();
  }, []);
  return (
    <Container
      sx={{
        minWidth: "360px",
        width: "100%",
        maxWidth: "1440px",
        minHeight: "100vh",
        backgroundColor: getColorWithMode(darkMode, "base"),
        padding: "0px 0px 0px 0px",
        paddingBottom: "20px",
      }}
    >
      <Stack
        direction="row"
        width="100%"
        sx={{ padding: "5px 5px 5px 5px" }}
        alignItems={"center"}
        spacing={2}
      >
        <Typography>Tools: </Typography>
        <Link href="/admin/gearmanager">
          <Button variant="outlined">Gear Manager</Button>
        </Link>
        <Link href="/admin/reviews">
          <Button variant="outlined">Review Manager</Button>
        </Link>
        <Link href="/admin/livepricingaudit">
          <Button variant="outlined">Live Pricing Audit</Button>
        </Link>
        <Link href="/admin/articleBuilder">
          <Button variant="outlined">Article Builder</Button>
        </Link>
      </Stack>
      {data ? (
        <Container sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            sx={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            Daily Values
          </Typography>
          <Stack
            direction="row"
            gap={2}
            sx={{ justifyContent: "center" }}
            useFlexGap
            flexWrap="wrap"
          >
            {Object.keys(data.today).map((key) => (
              <Paper
                elevation={4}
                key={key}
                sx={{ height: "200px", width: "200px" }}
              >
                <Stack>
                  <Typography variant="h6" sx={{ paddingTop: "5px" }}>
                    {key}
                  </Typography>
                  <Typography variant="h4" sx={{ lineHeight: "100px" }}>
                    {data.today[key]}
                  </Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
          <Typography
            variant="h5"
            sx={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            Total Values
          </Typography>
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            {Object.keys(data.total).map((key) => (
              <Paper
                elevation={4}
                key={key}
                sx={{ height: "200px", width: "200px" }}
              >
                <Stack>
                  <Typography variant="h6" sx={{ paddingTop: "5px" }}>
                    {key}
                  </Typography>
                  <Typography variant="h4" sx={{ lineHeight: "100px" }}>
                    {data.total[key]}
                  </Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
          <Typography
            variant="h5"
            sx={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            Trends
          </Typography>
          <Button
            onClick={() => {
              setIsDaily(!isDaily);
            }}
          >
            {isDaily ? "By Month" : "By Day"}
          </Button>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Paper elevation={4} sx={{ height: "600px", width: "1000px" }}>
              <Stack>
                <Typography variant="h6" sx={{ paddingTop: "5px" }}>
                  Session Breakdown
                </Typography>
                <Line
                  sx={{ maxHeight: "350px", width: "500px" }}
                  data={getMultiLineDataSetFromSessions(data.timeline, isDaily)}
                />
              </Stack>
            </Paper>
            <Paper elevation={4} sx={{ height: "600px", width: "1000px" }}>
              <Stack>
                <Typography variant="h6" sx={{ paddingTop: "5px" }}>
                  Link Click Breakdown
                </Typography>
                <Line
                  sx={{ maxHeight: "350px", width: "500px" }}
                  data={getMultiLineDataForLinkClicks(data.timeline, isDaily)}
                />
              </Stack>
            </Paper>
            {data.timeline.actions.map((key) => {
              const labels = data.timeline.dates;
              let monthValue = 0;
              let currentMonthKey = data.timeline.dates[0].slice(0, 7);
              const timelineMonthlyData = [];
              const monthlyLabels = [currentMonthKey];
              const timelineData = data.timeline.dates.map((date) => {
                const monthKey = date.slice(0, 7);
                if (key === "linkClick" || key === "session") {
                  if (data.timeline[date][key]) {
                    const dailyValue = data.timeline[date][key].reduce(
                      (acc, curr) => {
                        acc = curr.count + acc;
                        return acc;
                      },
                      0
                    );
                    if (monthKey === currentMonthKey) {
                      monthValue += dailyValue;
                    } else {
                      timelineMonthlyData.push(monthValue);
                      monthValue = dailyValue;
                      currentMonthKey = monthKey;
                      monthlyLabels.push(currentMonthKey);
                    }
                    return dailyValue;
                  } else {
                    return 0;
                  }
                } else {
                  if (data.timeline[date][key]) {
                    const dailyValue = data.timeline[date][key][0];
                    if (monthKey === currentMonthKey) {
                      monthValue += dailyValue;
                    } else {
                      timelineMonthlyData.push(monthValue);
                      monthValue = dailyValue;
                      currentMonthKey = monthKey;
                      monthlyLabels.push(currentMonthKey);
                    }
                    return dailyValue;
                  } else {
                    return 0;
                  }
                }
              });
              timelineMonthlyData.push(monthValue);

              const dataToRender = {
                labels: isDaily ? labels : monthlyLabels,
                datasets: [
                  {
                    label: getFormattedLabelFromKey(key),
                    data: isDaily ? timelineData : timelineMonthlyData,
                    borderColor: "rgb(53, 162, 235)",
                  },
                ],
              };
              return (
                <Paper
                  elevation={4}
                  key={key}
                  sx={{ height: "600px", width: "1000px" }}
                >
                  <Stack>
                    <Typography variant="h6" sx={{ paddingTop: "5px" }}>
                      {getFormattedLabelFromKey(key)}
                    </Typography>
                    <Line
                      sx={{ maxHeight: "350px", width: "500px" }}
                      data={dataToRender}
                    />
                  </Stack>
                </Paper>
              );
            })}
          </Stack>
        </Container>
      ) : (
        <Container sx={{ textAlign: "center" }}>
          You do not have permission to see this
        </Container>
      )}
    </Container>
  );
};

export default AdminContainer;
