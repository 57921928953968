import React from "react";
import { useMediaQuery } from "react-responsive";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import { getColorWithMode } from "../constants/colors";
import PackTableContainer from "./PackTableContainer";
import PackChecklistContainer from "./PackChecklistContainer";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import OverallSummaryRow from "./OverallSummaryRow";
import {
  updatePack,
  getUsersDefaultPack,
  deletePackWithId,
  setUserDefaultPack,
  generateShareableLink,
  getUserIsLoggedIn,
  handleUpdatePackUnit,
  getWeightInGrams,
} from "../utils";
import { exportToCSV } from "../utils/pack";
import SideContainer from "./SideContainer";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import NEW_TABLE from "../constants/newTableStructure";
import { useEffect } from "react";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import IconButton from "@mui/material/IconButton";
import KebabMenu from "./KebabMenu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Collapse, Tooltip } from "@mui/material";
import SkeletonPack from "./SkeletonPack";
import PackDescription from "./PackDescription";
import PackSettings from "./PackSettings";
import { Helmet } from "react-helmet";
import { debounce } from "lodash";
import update from "immutability-helper";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const createSideMenuKey = (tableData) => {
  let dataArray = [];
  tableData.forEach((table) => {
    dataArray.push(table.title);
    Object.values(table.rows).forEach(({ weight, quantity, item }) =>
      dataArray.push(`${weight}${quantity}${item}`)
    );
  });
  return dataArray.join("");
};

const MainContainer = ({
  tableId,
  tableData,
  setTableData,
  setTableId,
  defaultPackId,
  setDefaultPackId,
  packName,
  setPackName,
  setShareableLink,
  setShareDialogIsOpen,
  handleSetAuthenticationIsOpen,
  packDescription,
  setPackDescription,
  packIsLoading,
  setPackIsLoading,
  setMainAlertIsOpen,
  setMainAlertDetails,
  handleSelectNewPack,
  unit,
  setUnit,
  darkMode,
  packSettingsTags,
  setPackSettingsTags,
  setPackVisibility,
  packVisibility,
  handleUpdatePackSettings,
  packAnalytics,
  setPackAnalytics,
  setAffiliateLinksAreOff,
  affiliateLinksAreOff,
  packCurrency,
  setPackCurrency,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const [packHasLoaded, setPackHasLoaded] = React.useState(false);
  const [packVisualizationToggleState, setPackVisualizationToggleState] =
    React.useState(false);
  const [isViewedAsChecklist, setIsViewedAsChecklist] = React.useState(false);
  const [sideNavIsOpen, setSideNavIsOpen] = React.useState(true);

  const handleSetUnit = (unit) => {
    handleUpdatePackUnit(tableId, unit);
    setUnit(unit);
  };

  const handleViewAsChecklist = () => {
    setIsViewedAsChecklist(true);
  };

  const handleLeaveChecklist = () => {
    setIsViewedAsChecklist(false);
  };

  useEffect(() => {
    setIsViewedAsChecklist(false);
  }, [tableId]);

  useEffect(() => {
    (async () => {
      if (!tableId) {
        setPackIsLoading(true);
        const {
          userTable,
          tableId,
          packName,
          packDescription,
          packUnit,
          tags,
          isPublic,
          analytics,
          hasAffiliateLinksTurnedOff,
          packCurrency,
        } = await getUsersDefaultPack();
        setPackDescription(packDescription || "");
        setPackVisibility(isPublic);
        setPackSettingsTags(tags);
        setPackName(packName);
        setTableData(userTable);
        setTableId(tableId);
        setDefaultPackId(tableId);
        setPackAnalytics(analytics || {});
        setAffiliateLinksAreOff(hasAffiliateLinksTurnedOff || false);
        setPackHasLoaded(true);
        setPackIsLoading(false);
        setPackCurrency(packCurrency);
        if (packUnit) setUnit(packUnit);
      }
    })();
  }, []);

  const updatePackTitle = async (e) => {
    const packName = e.target.value;
    if (packHasLoaded) {
      await updatePack(tableId, { packName });
      setPackName(packName);
    }
  };

  const debouncedUpdatePackTitle = debounce(updatePackTitle, 500);

  // Use to modify the pack e.g delete a table, add a table, update a row
  const modifyPack = React.useCallback(
    async (newTableData) => {
      setTableData(newTableData);
      await updatePack(tableId, { data: newTableData });
    },
    [tableId, setTableData]
  );

  const handleAddNewRow = () => {
    const newData = [...tableData];
    newData.push({ ...NEW_TABLE });
    modifyPack(newData);
  };

  const handleDeleteTable = (tableIndex) => {
    const newData = [...tableData];
    newData.splice(tableIndex, 1);
    modifyPack(newData);
  };

  const handleSharePack = async () => {
    const { shareableLink } = await generateShareableLink(tableId);
    setShareableLink(shareableLink);
    setShareDialogIsOpen(true);
  };

  const handleDeletePack = async () => {
    await deletePackWithId(tableId);
    const {
      tableId: newTableId,
      packName,
      userTable,
    } = await getUsersDefaultPack();
    setTableId(newTableId);
    setTableData(userTable);
    setDefaultPackId(newTableId);
    setPackName(packName);
  };

  const handleMarkAsDefault = async () => {
    if (defaultPackId === tableId) return;
    await setUserDefaultPack(tableId);
    setDefaultPackId(tableId);
  };

  const handleSetTableData = (index, key, value) => {
    if (key === "markedAsWeightType") {
      const newData = [...tableData];
      const valueToEnter =
        newData[index][key] === value ? WEIGHT_TYPES.BASE : value;
      newData[index][key] = value;
      Object.keys(newData[index].rows).forEach((key) => {
        newData[index].rows[key] = {
          ...newData[index].rows[key],
          weightType: valueToEnter,
        };
      });
      modifyPack(newData);
    } else {
      const newData = [...tableData];
      newData[index][key] = value;
      modifyPack(newData);
    }
  };

  const moveTable = React.useCallback(
    (dragIndex, hoverIndex) => {
      setTableData((prevTableData) => {
        const newData = update(prevTableData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevTableData[dragIndex]],
          ],
        });
        modifyPack(newData);
        return newData;
      });
    },
    [modifyPack, setTableData]
  );

  const handleExportToCSV = () => {
    exportToCSV(packName, tableData);
  };

  const { weightInGrams, price } = tableData.reduce(
    (acc, curr) => {
      const { summedRowWeight, summedRowPrice } = Object.values(
        curr.rows
      ).reduce(
        (acc, curr) => {
          return {
            summedRowWeight:
              acc.summedRowWeight +
              getWeightInGrams(curr.weight, curr.unit) * curr.quantity,
            summedRowPrice: acc.summedRowPrice + curr.price * curr.quantity,
          };
        },
        { summedRowWeight: 0, summedRowPrice: 0 }
      );
      return {
        weightInGrams: acc.weightInGrams + summedRowWeight,
        price: acc.price + summedRowPrice,
      };
    },
    {
      weightInGrams: 0,
      price: 0,
    }
  );

  return (
    <Container
      fixed
      style={{
        backgroundColor: getColorWithMode(darkMode, "base"),
        width: "100%",
        marginLeft: "0px",
        paddingLeft: "0px",
        minWidth: "737px",
      }}
    >
      <Helmet>
        <title>{packName}</title>
      </Helmet>

      {isViewedAsChecklist ? (
        <PackChecklistContainer
          tableData={tableData}
          darkMode={darkMode}
          handleLeaveChecklist={handleLeaveChecklist}
          modifyPack={modifyPack}
          packName={packName}
        />
      ) : (
        <Stack
          justifyContent={"space-between"}
          alignContent={isMobile ? "center" : ""}
          alignItems="center"
          sx={{
            minHeight: "720px",
            margin: "0 auto",
          }}
        >
          <Stack>
            <Button
              size={isMobile ? "large" : "small"}
              sx={{ marginTop: "10px" }}
              onClick={() => {
                setSideNavIsOpen(!sideNavIsOpen);
              }}
            >
              {sideNavIsOpen ? "Hide " : "Show "} Pack Stats & Gear Suggestions
            </Button>
            <Collapse in={sideNavIsOpen}>
              <SideContainer
                key={`${tableId} - ${createSideMenuKey(tableData)}`}
                tableData={tableData}
                unit={unit}
                toggleState={packVisualizationToggleState}
                setToggleState={setPackVisualizationToggleState}
                packIsLoading={packIsLoading}
                darkMode={darkMode}
              />
            </Collapse>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            {getUserIsLoggedIn() ? (
              <></>
            ) : (
              <ThemeProvider theme={theme}>
                <Alert
                  severity="warning"
                  sx={{
                    width: "calc(100% - 30px)",
                    marginLeft: "15px",
                    marginTop: "5px",
                    color: "#a45729",
                    backgroundColor: "#ffdfb3",
                  }}
                >
                  Your pack is being temporarily saved on your browser. To keep
                  it forever (and share it), please{" "}
                  <Link
                    sx={{
                      color: "#a45729",
                      textDecorationColor: "#a45729",
                      "&:hover": {
                        cursor: "pointer",
                        fontWeight: "550",
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSetAuthenticationIsOpen("LOGIN");
                    }}
                  >
                    sign in
                  </Link>
                  ! (You can import it from your Account Settings after signing
                  up.)
                </Alert>
              </ThemeProvider>
            )}
            <Container
              fixed
              sx={{
                overflowX: "hidden",
                marginTop: "10px",
                marginLeft: "0px",
                width: "100%",
                minWidth: "100%",
                maxWidth: "100vw",
                minHeight: `${
                  getUserIsLoggedIn()
                    ? sideNavIsOpen
                      ? "475px"
                      : "785px"
                    : sideNavIsOpen
                    ? "420px"
                    : "730px"
                }`,
                paddingLeft: isMobile ? "5px" : "20px",
                paddingRight: "0px",
              }}
            >
              <Stack spacing={1} sx={{ marginTop: isMobile ? "50px" : "0px" }}>
                {packIsLoading ? (
                  <SkeletonPack />
                ) : (
                  <>
                    <Stack
                      direction="row"
                      sx={{ width: "calc(100% + 10px)" }}
                      alignItems="center"
                    >
                      <TextField
                        multiline={isMobile}
                        defaultValue={packName}
                        key={`${tableId}_title`}
                        onChange={debouncedUpdatePackTitle}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        inputProps={{
                          maxLength: 64,
                          style: { "&:hover": { color: "red" } },
                        }}
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-root": {
                            marginTop: isMobile ? "-15px" : "0px",
                            fontSize: isMobile ? "44px" : "30px",
                          },
                          "&:hover": {
                            backgroundColor: getColorWithMode(
                              darkMode,
                              "secondary"
                            ),
                          },
                        }}
                      ></TextField>
                      <Button
                        variant={darkMode ? "outlined" : "contained"}
                        color="primary"
                        startIcon={<ShareIcon />}
                        sx={{
                          marginLeft: "15px",
                          paddingTop: "5px",
                          height: isMobile ? "50px" : "40px",
                        }}
                        onClick={handleSharePack}
                      >
                        Share
                      </Button>
                      <IconButton
                        onClick={handleMarkAsDefault}
                        sx={{
                          width: isMobile ? "48px" : "30px",
                          height: isMobile ? "48px" : "30px",
                          marginLeft: "20px",
                        }}
                      >
                        {defaultPackId === tableId ? (
                          <Tooltip title="This pack is your default pack">
                            <StarIcon
                              sx={{
                                color: "gold",
                                width: isMobile ? "42px" : "30px",
                                height: isMobile ? "42px" : "30px",
                                "&:hover": {
                                  cursor: "default",
                                  fontWeight: "550",
                                },
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Set this pack as your default pack">
                            <StarOutlineIcon
                              sx={{ width: "30px", height: "30px" }}
                            />
                          </Tooltip>
                        )}
                      </IconButton>
                      <KebabMenu
                        handleDeletePack={handleDeletePack}
                        tableId={tableId}
                        setMainAlertDetails={setMainAlertDetails}
                        setMainAlertIsOpen={setMainAlertIsOpen}
                        handleSelectNewPack={handleSelectNewPack}
                        handleViewAsChecklist={handleViewAsChecklist}
                        handleExportToCSV={handleExportToCSV}
                      />
                    </Stack>
                    {getUserIsLoggedIn() ? (
                      <PackSettings
                        userPackTags={packSettingsTags}
                        visibility={packVisibility}
                        handleUpdatePackSettings={handleUpdatePackSettings}
                        packAnalytics={packAnalytics}
                        packCurrency={packCurrency}
                      />
                    ) : (
                      <></>
                    )}
                    <PackDescription
                      packDescription={packDescription}
                      setPackDescription={setPackDescription}
                      tableId={tableId}
                      darkMode={darkMode}
                    />
                    {tableData.map((individualTableData, tableIndex) => (
                      <PackTableContainer
                        key={`${individualTableData.title}-${individualTableData.subtitle}`}
                        id={`${individualTableData.title}-${individualTableData.subtitle}`}
                        tableData={individualTableData}
                        tableIndex={tableIndex}
                        handleSetTableData={handleSetTableData}
                        handleDeleteTable={handleDeleteTable}
                        tableId={tableId}
                        packIsLoading={packIsLoading}
                        darkMode={darkMode}
                        affiliateLinksAreOff={affiliateLinksAreOff}
                        moveTable={moveTable}
                        packCurrency={packCurrency}
                        packSettingsTags={packSettingsTags}
                      />
                    ))}
                  </>
                )}
                <Box sx={{ "&.MuiBox-root": { marginTop: "5px" } }}>
                  <Button
                    variant="standard"
                    sx={{ color: darkMode ? "lightBlue" : "#1b7ced" }}
                    onClick={() => {
                      handleAddNewRow();
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add New Category
                  </Button>
                </Box>
              </Stack>
            </Container>
            <OverallSummaryRow
              tableData={tableData}
              unit={unit}
              setUnit={handleSetUnit}
              darkMode={darkMode}
              weightInGrams={weightInGrams}
              price={price}
              packCurrency={packCurrency}
            />
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default MainContainer;
